<template lang="pug">
  .split-image-compare
    .a-box-xs-1x1
      .a-box-content
        .left-image-wrap.absolute-wrap(v-if="leftImage && leftImage.url && !virtualTryOnLanding")
          img.full-width(:src="leftImage.url" :alt="leftImage.alt_text")
          p.image-text.original.upper.semi-bold.text-color-2(v-show="rightImage.url") Original
        .right-image-wrap(v-if="rightImage && rightImage.url" :class="{ 'full-size-img': !leftImage || !leftImage.url || virtualTryOnLanding }")
          img(:src="rightImage.url" :alt="rightImage.alt_text")
          p.image-text.new.upper.semi-bold.text-color-2(v-show="rightImage.url") New

</template>

<script>
  export default {
    name: 'SplitImageCompare',
    props: {
      virtualTryOnLanding: {
        type: Boolean,
        default: false
      },
      leftImage: {
        type: Object,
        default: () => ({})
      },
      rightImage: {
        type: Object,
        default: () => ({})
      }
    }
  };
</script>

<style lang="stylus">
  .split-image-compare
    .left-image-wrap
    .right-image-wrap
      position absolute
      top 0
      bottom 0
      overflow hidden
      background-color color-fog-light

      img
        position absolute
        top 0
        bottom 0
        height 100%

    .left-image-wrap
      left 0
      right 0

      img
        left 0

    .right-image-wrap
      left 50%
      right 0
      border-left 2px solid color-white

      &.full-size-img
        left 0
        right 0
        border-left 0

      img
        right 0

    .image-text
      position absolute
      background-color rgba(color-white, 0.8)
      border-radius 0.5em
      padding 0.25em 0.55em
      bottom 1em

      &.new
        right 1em

      &.original
        left 1em
</style>
