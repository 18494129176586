import vuePressSvc from "@services/vuePressSvc";

export const state = {
  loadingPressAssets: false,
  allPressAssetsLoaded: false,
  pressAssets: null,
};

export const actions = {
  loadAllPressAssets({ state, commit }) {
    if (state.loadingPressAssets) {
      return;
    }
    commit('setLoadingPressAssets', true);

    vuePressSvc.loadAllPressAssets().then(({ data }) => {
      commit('setAllPressAssetsLoaded', true);
      commit('setLoadingPressAssets', false);
      commit('setPressAssets', data);
    }).catch(() => {
      commit('setLoadingPressAssets', false);
    });
  },
};

export const getters = {};

export const mutations = {
  setAllPressAssetsLoaded(state, value) {
    state.allPressAssetsLoaded = !!value;
  },

  setLoadingPressAssets(state, value) {
    state.loadingPressAssets = !!value;
  },

  setPressAssets(state, value) {
    state.pressAssets = value;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};