import mrApi from './mrApi';

export default {
  getCustomerUseCredits,
  setCustomerUseCredits
};

export function getCustomerUseCredits() {
  return mrApi.get('/api/credits/getCustomerUseCredits');
}

export function setCustomerUseCredits(params) {
  return mrApi.post('/api/credits/setCustomerUseCredits', params);
}

