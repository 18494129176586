import { getObjProperty } from '@utilities/mrVueUtils';
import * as vueVideoChatSvc from '@services/vueVideoChatSvc';

const BUFFERING_TIME = 5;

const state = {
  booking: null,
  customer: {},
  room: null,
  selectedReason: undefined,
  availability: null
};

const getters = {
  customerInfo(state, getters, rootState) {
    const info = getObjProperty(rootState, 'customer.info');
    if (!info) {
      return {};
    }

    const { first_name, last_name, email, phone_main } = info;
    let fullName = [];
    if (first_name) {
      fullName.push(first_name);
    }
    if (last_name) {
      fullName.push(last_name);
    }
    return {
      name: fullName.join(' '),
      email,
      phone: phone_main,
    };
  },
};

const actions = {
  setCustomer({ commit }, customer) {
    commit('setCustomer', customer);
  },

  setBooking({ commit }, booking) {
    commit('setBooking', booking);
  },

  setRoom({ commit }, room) {
    commit('setRoom', room);
  },

  setSelectedReason({ commit }, selectedReason) {
    commit('setSelectedReason', selectedReason);
  },

  async getOnDemandAvailability({ commit, state }) {
    if (!state.selectedReason) {
      return;
    }
    const { duration } = state.selectedReason;
    const response = await vueVideoChatSvc.getOnDemandAvailability({
      time: duration + BUFFERING_TIME,
    });

    const availability = getObjProperty(response, 'data.availability');

    /* commit('setAvailability', true); */
    commit('setAvailability', availability);
  }
};

const mutations = {
  setCustomer(state, customer = {}) {
    state.customer = customer;
  },

  setBooking(state, booking) {
    state.booking = booking;
  },

  setRoom(state, room) {
    state.room = room;
  },

  setSelectedReason(state, selectedReason) {
    state.selectedReason = selectedReason;
  },
  setAvailability(state, availability) {
    state.availability = availability;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};