<template lang="pug">
  footer#footer.mr-footer
    .row
      .xs-12.footer-info(v-if="!simpleFooter")
        .footer-links.footer-accordion.xs-12
          .accordion-section.xs-12(v-for="section in sections" :key="`section-${section.title}`" :class="getSectionClasses(section)")
            .header-wrap.xs-12(@click="openSection(section)")
              p.header-text.upper.semi-bold.font-small
                span.accordion-icon.lg-hide(:class="sectionIsCurrent(section) ? 'icon-neg' : 'icon-pos'" tabindex="0") &nbsp;
                | {{section.title}}

            .links-wrap.xs-12(:aria-expanded="section.title === openSectionTitle" :aria-hidden="displayIsDesktopMDOrLess && !sectionIsCurrent(section)")
              ul
                li(v-for="(link, index) in filterLinks(section.links)" :key="index")
                  a(v-if="showLink(link)" :href="link.href" target="_self" :tabindex="getTabindex(section)" @click="trackIfRequired(section,link)" :aria-label="link.ariaLabel || link.text") {{ link.text }}
                  button(v-else-if="link.button" :id="link.id" :class="link.class" :aria-label="link.ariaLabel" :tabindex="getTabindex(section)" v-html="link.text")

        .footer-share-signup.xs-12.text-center(v-if="!showMensMenu")
          .share.xs-12.md-6.text-left
            .share-wrap
              span#extole_zone_global_footer

          .xs-12.md-6
            email-capture-footer-form(v-if="isMounted")

      #footer-bottom.copyright-n-social.xs-12
        .xs-12.lg-4.lg-right.social
          ul
            li.header find us on:
            li(v-for="(social, index) in socials" :key="index")
              a(:href="social.url" target="_blank" :aria-label="buildSocialLinkAreaLabel(social.title)" :class="social.icon" tabindex="0")
                span.ada-tooltip
                  | {{ social.title }}

        .copyright.xs-12.lg-8
          ul
            li.header &copy; {{ (new Date()).getFullYear() }} Madison Reed, Inc.
</template>

<script>
  import { mapState, mapGetters } from 'vuex';
  import dataToolSvc from '@services/dataToolSvc';
  import menuMixin from "@mixins/menuMixin";

  import {
    DEFAULT_BUTTONS,
    DEFAULT_SOCIALS,
    MENS_BUTTONS,
    MENS_SOCIALS,
  } from './constants';

  const EmailCaptureFooterForm = () => import('../EmailCapture/EmailCaptureFooterForm');

  export default {
    name: 'MrFooter',

    inject: ['getObjProperty'],

    components: {
      EmailCaptureFooterForm,
    },

    mixins: [ menuMixin ],

    props: {
      simpleFooter: {
        type: Boolean,
        default: false,
      },
      removeFaqsAndTerms: {
        type: Boolean,
        default: false,
      },
      changeFaqsAndTerms: {
        type: Boolean,
        default: false,
      },
    },

    data: () => ({
      openSectionTitle: undefined,
      isMounted: false,
      sections: null,
      dgInterval: null
    }),

    computed: {
      ...mapState('global', ['windowData']),
      ...mapGetters('global', ['showLimitlessPlusFooter']),
      ...mapGetters('siteNav', ['showMensMenu']),

      buttons() {
        if (this.showMensMenu) {
          return MENS_BUTTONS;
        }
        return DEFAULT_BUTTONS;
      },

      socials() {
        if (this.showMensMenu) {
          return this.mix_copyOnlyElementsToShow(MENS_SOCIALS);
        }
        return this.mix_copyOnlyElementsToShow(DEFAULT_SOCIALS);
      },

      hideTerms() {
        return this.removeFaqsAndTerms || this.changeFaqsAndTerms;
      },

      displayIsDesktopMDOrLess() {
        return this.windowData.breakpoint === 'mobile' || this.windowData.breakpoint === 'tablet' || this.windowData.breakpoint === 'desktop-md';
      },
    },

    mounted() {
      this.isMounted = true;
      dataToolSvc.getData({
        mixinKey: 'footer'
      }).then(res => {
        let sections = null;
        if (this.showMensMenu) {
          sections = this.getObjProperty(res, 'data.mens.sections') || [];
        } else {
          sections = this.getObjProperty(res, 'data.default.sections') || [];
        }

        sections.forEach(section => {
          this.buttons.forEach(button => {
            if (button.insertInto == section.title) {
              if (button.insertAt == 'end') {
                section.links.push(button);
              } else {
                section.links.splice(button.insertAt, 0, button);
              }
            }
          });
        });

        this.sections = sections;

        // DataGrail integration
        this.dgInterval = setInterval(() => {
          let dgBtn = document.getElementById('dg-btn');
          if (window.dgInitialized && window?.DG_BANNER_API?.showConsentBanner && dgBtn) {
            dgBtn.addEventListener('click', () => {
              window.DG_BANNER_API.showConsentBanner();
            });
            clearInterval(this.dgInterval);
          }
        }, 300);
      });
    },

    methods: {
      filterLinks(links) {
        return this.mix_copyOnlyElementsToShow(links);
      },

      getTabindex(section) {
        return !this.displayIsDesktopMDOrLess || this.sectionIsCurrent(section) ? null : -1;
      },

      buildSocialLinkAreaLabel(title) {
        return `${title} (opens in a new tab)`;
      },

      getSectionClasses(section) {
        const qty = Math.ceil(12 / this.sections.length);
        const classes = [`lg-${qty}`];
        if (section.title === this.openSectionTitle) {
          classes.push('open');
        }
        return classes.join(' ');
      },

      openSection(section) {
        if (!this.displayIsDesktopMDOrLess) {
          return;
        }

        if (section.title === this.openSectionTitle) {
          this.openSectionTitle = '';
        } else {
          this.openSectionTitle = section.title;
        }
      },

      sectionIsCurrent(section) {
        return section.title === this.openSectionTitle;
      },

      async trackIfRequired(section, link) {
        const internalEventName = (link.button && link.internalTrackEventName) || link.internalTrackEventName || `${section.title}/${link.title}`;

        const eventName = `Site Footer - ${internalEventName} Clicked`;
        this.trackMREventAndRedirect(eventName, link.href);
      },

      showIfInExperiment(link) {
        if (!link.experiment || this.experiments[link.experiment] == "B") { // for any footer experiment
          return true;
        } else if (link.title == "Limitless Plus" && this.showLimitlessPlusFooter) { // specific for limitless plus
          return true;
        } else {
          return false;
        }
      },

      showLink(link) {
        return link.text && (link.text !== 'Terms' || !this.hideTerms) && link.href && this.showIfInExperiment(link);
      }
    },
  };
</script>

<style lang='stylus'>
  .mr-footer
    .links-wrap
      button
        color inherit
        
</style>