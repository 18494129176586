import mrApi from './mrApi';

export default {
  searchAll,
};

export function searchAll(params) {
  return mrApi.get('/api/search/searchAll', { params });
}

