<template lang="pug">
  .site-nav-desktop-v2-shop-nav
    ul.left-nav(role="menu" :class="'rows-' + rightNavRows")
      li(v-for="subNav in subNavs" :key="subNav.title")
        button.sub-nav-title.btn-reset.text-color-3.xs-f-medium.max-at-tweak(@click="openSubNav(subNav)" role="menuitem" :class="{'open bold': isSubNavOpen(subNav)}")
          .sub-nav-title-content(tabindex="-1") {{ subNav.title }}
          span.ada-tooltip {{ isSubNavOpen(subNav) ? ' nav open' : ' nav closed' }}

        transition(name="fade")
          ul.right-nav(v-if="openedSubNav && openedSubNav.title == subNav.title" role="menu")
            li(v-for="(navItem, index) in subNav.navItems" :key="index" role="none")
              a.xs-f-medium.lg-f-small.max-at-tweak(v-if="navItem.image" :href="navItem.link.href" @click="mix_trackFTVNavClick('shop', navItem.link.text)" role="menuitem")
                img(:src="navItem.image.url")
                .main-title.text-color-1.xs-f-xsmall.lg-f-small.max-at-tweak {{ navItem.link.text }}
              
              a.no-image.xs-f-medium.lg-f-small.max-at-tweak(v-else :href="navItem.link.href" @click="mix_trackFTVNavClick('shop', navItem.link.text)" role="menuitem" :class="{'no-image': !navItem.image}")
                .a-box-xs-1x1
                  .a-box-content.ui-color-11-bg
                    .main-title.hv-center.brand-color-1.upper.bold.xs-f-small.lg-f-small.max-at-tweak {{ navItem.link.text }}
    
      li.button-cta-wrap(v-if="hasButtonCta")
        a.mr-btn.btn-block.xs-f-xsmall.max-at-tweak(:href="shopNav.buttonCta.href") {{shopNav.buttonCta.text}}

    transition(name="fade")
      .image-cta-wrap.xs-f-medium.max-at-tweak(v-if="showImageCta")
        a.image-cta(:href="shopNav.imageCta.link.href")
          img(:src="shopNav.imageCta.desktopImage.url")
          span.ada-tooltip {{shopNav.imageCta.link.text || shopNav.imageCta.desktopImage.alt_text}}

</template>

<script>
  import SiteNavTrackMixin from "@components/SiteNav/SiteNavTrackMixin/SiteNavTrackMixin";

  export default {
    name: "SiteNavDesktopV2ShopNav",

    mixins: [SiteNavTrackMixin],

    data() {
      return {
        openedSubNav: null
      };
    },

    props: {
      shopNav: {
        type: Object
      }
    },

    computed: {
      subNavs() {
        return this.shopNav.subNavs;
      },

      rightNavRows() {
        if (!this.openedSubNav) {
          return 0;
        } else {
          return Math.ceil(this.openedSubNav.navItems.length / 3);
        }
      },

      hasButtonCta() {
        return this.shopNav.buttonCta && this.shopNav.buttonCta.text && this.shopNav.buttonCta.href;
      },

      showImageCta() {
        return !this.openedSubNav && this.shopNav.imageCta && this.shopNav.imageCta.desktopImage && this.shopNav.imageCta.link && this.shopNav.imageCta.link.href;
      }
    },

    methods: {
      openSubNav(subNav) {
        this.openedSubNav = subNav;
        this.mix_trackFTVNavViewed(subNav.title);
      },

      isSubNavOpen(subNav) {
        return this.openedSubNav && this.openedSubNav.title == subNav.title;
      }
    }
  };
</script>

<style scoped lang="stylus">
  .site-nav-desktop-v2-shop-nav
    position relative
    
    .left-nav
      position relative
      text-align left
      padding 1em 0
      padding-right 0.5em
      width 16em
      min-height 34em
      
      &.rows-3
        min-height 49em
        
      &.rows-4
        min-height 65em
      
      .sub-nav-title
        padding 0.25em 0
        margin 0.5em 0
        width 100%
        text-align left
        
        .sub-nav-title-content
          display inline-block
        
        &.open
          color text-color-1
          
          .sub-nav-title-content
            border-bottom 3px solid brand-color-1
          
      .right-nav
        position absolute
        top 0
        left 17em
        width 41em
        display flex
        flex-direction row
        flex-wrap wrap
        justify-content space-between
        padding 1.5em 0
        
        li
          width 31%
          
          a
            display inline-block
            padding-bottom 1em
            margin-bottom 0.25em
            width 100%
            
            img
              width 100%
              border-radius 0.5em
            
            &.no-image
              white-space nowrap
              
              .a-box-content
                border-radius 0.5em
                
    .image-cta-wrap
      position absolute
      top 0
      left 16em
      width 40em
      padding 1.5em
      
      .image-cta
        display inline-block
        text-align center
        img
          width 100%
      
</style>
