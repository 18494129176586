import vueAdvisorSvc from '@services/vueAdvisorSvc';
import { mapActions } from 'vuex';

export default {
  data() {
    return {
      advisorUpdated: 0
    };
  },

  computed: {
    formatedColorMatches() {
      var matches = [];

      if (this.colorMatches) {
        if (this.colorMatches.bestMatch) {
          matches.push({
            key: 'bestMatch',
            label: "Best<br/> match",
            product: this.colorMatches.bestMatch
          });
        }

        if (this.colorMatches.mostPopular) {
          matches.push({
            key: 'mostPopular',
            label: "Most<br/> popular",
            product: this.colorMatches.mostPopular
          });
        }

        if (this.colorMatches.customerMatch && this.uniqueMatch('customerMatch')) {
          matches.push({
            key: 'customerMatch',
            label: "Your<br/> choice",
            product: this.colorMatches.customerMatch
          });
        }

        if (this.colorMatches.coloristMatch && this.uniqueMatch('coloristMatch')) {
          matches.push({
            key: 'coloristMatch',
            label: "Colorist's<br/> choice",
            product: this.colorMatches.coloristMatch
          });
        }

        if (this.colorMatches.translatorMatch) {
          matches.push({
            key: 'translatorMatch',
            label: "Translator<br/> results",
            product: this.colorMatches.translatorMatch
          });
        }
      }

      return matches;
    },

    advisorAnswers() {
      if (this.advisorData) {
        return this.advisorData.answers;
      }
    },

    surveyAnswers() {
      if (this.advisorData) {
        return this.advisorData.surveys;
      }
    },

    advisorUpdatedAt() {
      if (this.advisorData) {
        return this.advisorData.updated_at;
      }
    }
  },

  asyncComputed: {
    advisorData: {
      lazy: true,
      get() {
        return vueAdvisorSvc.getCustomerAnswers().then(res => res.data);
      },
      watch() {
        this.advisorUpdated;
      }
    },

    colorMatches: {
      lazy: true,
      get() {
        return vueAdvisorSvc.getAllColorMatches().then(res => res.data).catch(() => null);
      },
      watch() {
        this.advisorUpdated;
      }
    },

    moreColorOptions: {
      lazy: true,
      get() {
        return vueAdvisorSvc.getMoreColorOptions().then(res => res.data).catch(() => null);
      },
      watch() {
        this.advisorUpdated;
      }
    },

    lightWorksRecommendation: {
      lazy: true,
      get() {
        return vueAdvisorSvc.getLightWorksRecommendations().then(res => {
          if (res && res.data && res.data.id) {
            return res.data[0];
          }
          return null;
        });
      },
      watch() {
        this.advisorUpdated;
      }
    },
  },

  methods: {
    ...mapActions('xsell', [
      'xsellOnAdvisorUpdate'
    ]),

    ...mapActions('colorAdvisor', ['isAdvisorCompleted']),

    uniqueMatch(checkKey) {
      var unique = true;

      if (this.colorMatches) {
        Object.keys(this.colorMatches).forEach(key => {
          if (key != checkKey) {
            if (this.colorMatches[key] && this.colorMatches[key].code == this.colorMatches[checkKey].code) {
              unique = false;
            }
          }
        });
      }

      return unique;
    },

    persistCustomerAnswers(params) {
      return new Promise((resolve, reject) => {
        vueAdvisorSvc.persistCustomerAnswers({ answers: params })
          .then(res => {
            this.advisorUpdated += 1;
            this.isAdvisorCompleted();
            this.xsellOnAdvisorUpdate();
            resolve(res);
          }).catch(err => {
            reject(err);
          });
      });
    }
  }
};
