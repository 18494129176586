const DesignSystemColors = () => import(/* webpackChunkName: "DesignSystem" */ './DesignSystemColors');
const DesignSystemFonts = () => import(/* webpackChunkName: "DesignSystem" */ './DesignSystemFonts');
const DesignSystemIcons = () => import(/* webpackChunkName: "DesignSystem" */ './DesignSystemIcons');
const DesignSystemCTAs = () => import(/* webpackChunkName: "DesignSystem" */ './DesignSystemCTAs');

export default [
  { path: '/design-system/colors', component: DesignSystemColors },
  { path: '/design-system/fonts', component: DesignSystemFonts },
  { path: '/design-system/icons', component: DesignSystemIcons },
  { path: '/design-system/ctas', component: DesignSystemCTAs },
  { path: '/design-system*', redirect: '/design-system/colors' }
];