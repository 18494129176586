<template lang='pug'>
  .mr-stars(@click="tryScroll" :class="{clickable: !!scrollFunction}")
    span(v-for="star in stars" :class="star.class")
    span(v-if="showTotal && totalReviews" :class="{underline: !!scrollFunction}") &nbsp;{{totalReviews}}
    span.ada-tooltip {{ rating }} out of 5 stars
</template>

<script>
  import vueProductSvc from '@services/vueProductSvc';

  export default {
    name: "Stars",

    inject: ['getObjProperty'],

    props: {
      rating: {
        type: [String, Number],
        default: 5
      },

      reviewsCount: {
        type: Number,
        default: 0,
      },

      showTotal: {
        type: Boolean,
        default: false
      },

      productId: {
        type: Number,
        default: undefined
      },

      scrollFunction: {
        type: Function,
        default: undefined
      },
    },

    data() {
      return {
        productRating: {},
      };
    },

    computed: {
      stars() {
        var starArray = [];
        var floorRating = Math.floor(this.productRating.average || +this.rating);
        var ceilRating = Math.ceil(this.productRating.average || +this.rating);

        for (var x = 1; x <= 5; x++) {
          starArray.push({
            class: x <= floorRating ? 'icon-star-full' : (x == ceilRating && ceilRating != floorRating) ? 'icon-star-half' : 'icon-star'
          });
        }

        return starArray;
      },

      totalReviews() {
        return (this.getObjProperty(this.productRating, 'count') === undefined ? this.reviewsCount : this.getObjProperty(this.productRating, 'count'));
      }
    },

    mounted() {
      if (this.productId) {
        return vueProductSvc.getAverageReviewsByProductId({ productId: this.productId }).then(res => this.productRating = res.data);
      }
    },

    methods: {
      tryScroll() {
        if (this.scrollFunction) {
          this.scrollFunction();
        }
      }
    },
  };
</script>
