import vueCreditsSvc from '@services/vueCreditsSvc';
import { getObjProperty } from '@utilities/mrVueUtils';

export const state = {
  customerUseCredits: false
};

export const actions = {
  loadCustomerUseCredits({ dispatch, commit }) {
  return vueCreditsSvc.getCustomerUseCredits().then(res => {
    let useCredits = getObjProperty(res, 'data.useCredits') || false;
      commit('setCustomerUseCredits', useCredits);
    }).catch(() => {
      dispatch('notifyError', null, { root: true });
    });
},

  updateCustomerUseCredits({state, dispatch, commit}) {
    let useCredits = !state.customerUseCredits;
    return vueCreditsSvc.setCustomerUseCredits({useCredits}).then(() => {
      commit('setUpdateCustomerUseCredits', state.customerUseCredits);
      dispatch('notifySuccess', 'Your rewards settings has been updated', { root: true });
    }).catch(() => {
      dispatch('notifyError', null, { root: true });
    });
  }
};

export const mutations = {
  setCustomerUseCredits(state, val) {
    state.customerUseCredits = val;
  },

  setUpdateCustomerUseCredits(state) {
    state.customerUseCredits = !state.customerUseCredits;
  }
};

export default {
  namespaced: true,
  state,
  actions,
  mutations
};