<template lang="pug">
  .site-nav-desktop-v2-sub-nav
    .sub-nav(v-for="nav in subNavs" :key="nav.title")
      .nav-title.bold.upper.xs-f-medium.lg-f-small.max-at-tweak.brand-color-1 {{ nav.title }}
      ul(role="menu")
        li.sub-menu-item.text-color-1.xs-f-medium.lg-f-small.max-at-tweak(v-for="(item, index) in nav.ctas" :key="index" role="none")
          a.text-color-1.xs-f-medium.lg-f-small.max-at-tweak(:href="item.link.href" @click="mix_trackFTVNavClick(nav.title, item.link.text)" role="menuitem") {{item.link.text}}


</template>

<script>
  import SiteNavTrackMixin from "@components/SiteNav/SiteNavTrackMixin/SiteNavTrackMixin";

  export default {
    name: "SiteNavDesktopV2SubNav",

    mixins: [SiteNavTrackMixin],

    props: {
      subNavs: {
        type: Array
      }
    }
  };
</script>

<style scoped lang="stylus">
  .site-nav-desktop-v2-sub-nav
    display flex
    flex-direction row
    flex-wrap wrap
    justify-content left
    
    .sub-nav
      text-align left
      padding 1em
      min-width 17em
      
      .sub-menu-item
        padding 0.5em 0

</style>
