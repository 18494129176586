import mrApi from './mrApi';

export default {
  newAddress,
  getAllAddressesByCustomerId,
  getRegionsByCountry,
  getSubscriptionsWithItemsByAddressId,
  saveAddress,
  deleteAddress,
  validateAddress,
  zipCheck
};

export function newAddress() {
  return mrApi.get('/api/customer/newAddress');
}

export function getAllAddressesByCustomerId() {
  return mrApi.get('/api/customer/getAllAddressesByCustomerId');
}

export function getRegionsByCountry(params) {
  return mrApi.get('/api/appLookup/getRegionsByCountry', { params });
}

export function getSubscriptionsWithItemsByAddressId(params) {
  return mrApi.get('/api/subscription/getSubscriptionsWithItemsByAddressId', { params });
}

export function saveAddress(params) {
  return mrApi.post('/api/customer/addOrUpdateAddress', params);
}

export function deleteAddress(params) {
  return mrApi.post('/api/customer/deleteAddress', params);
}

export function validateAddress(params) {
  return mrApi.post('/api/customer/validateAddressViaUsps', params);
}

export function zipCheck(zip) {
  return mrApi.get('/api/usps/zipCheck', { params: { zip }});
}
