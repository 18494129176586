<template lang="pug">
  div
    slot(:proxy="proxy")
</template>

<script>
  export default {
    name: 'proxy',

    props: ['rep'],

    data() {
      return {
        proxy: null
      }
    },

    watch: {
      rep(newVal) {
        this.assign(newVal);
      }
    },

    methods: {
      assign(val) {
        if (typeof val === 'object') {
          Object.assign(this.proxy, val);
        } else {
          this.proxy = val;
        }
      }
    },

    created() {
      this.assign(this.rep);
    }
  }
</script>
