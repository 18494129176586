
/**
 * mrApi Vue Plugin
 * 
 * This plugin installs an $mrApi property on the Vue object as a reference
 * to the mrApi module.  Components that were using $http via the Vue-Axios
 * module via 'Vue.$http' may use this instead for requests to mr website server.
 */
(function () {
  /**
   * Install plugin
   * @param {object} Vue Vue 
   * @param {object} mrApi mrApi module
   */
  
  function plugin(Vue, mrApi) {
    if (plugin.installed) {
      return;
    }

    plugin.installed = true;
  
    if (!mrApi) {
      console.error('mrApi is required.');
      return;
    }

    if (!mrApi.get || !mrApi.post) {
      console.log('Invalid mrApi interface.');
      return;
    }
  
    Vue.$mrApi = mrApi;
  
    Object.defineProperties(Vue.prototype, {
      $mrApi: {
        get() {
          return mrApi;
        }
      }
    });
  }
  
  if (typeof exports == 'object') {
    module.exports = plugin;
  } else if (define && typeof define == 'function' && define.amd) { // eslint-disable-line
    define([], function() { // eslint-disable-line
      return plugin; 
    });
  } else if (window.Vue && window.mrApi) {
    window.Vue.use(plugin, window.mrApi);
  }
})();