<template lang="pug">
  .toggle-input(:class="classNames")
    input(type="checkbox" :checked="value" @input="update" @focus="focus" @blur="clearFocus")

</template>

<script>
  export default {
    name: "ToggleInput",

    props: {
      value: {
        type: Boolean
      }
    },

    data() {
      return {
        focused: false
      }
    },

    computed: {
      classNames() {
        var classes = [];

        if (this.value) {
          classes.push('checked');
        }

        if (this.focused) {
          classes.push('focused');
        }

        return classes;
      }
    },

    methods: {
      focus() {
        this.focused = true;
      },

      clearFocus() {
        this.focused = false;
      },

      update() {
        this.$emit('input', !this.value);
      }
    }
  }
</script>

<style scoped lang="stylus">
  .toggle-input
    display inline-block
    vertical-align top
    box-sizing content-box
    position relative
    width 2em
    height 1em
    background-color color-fog
    border 2px solid color-fog
    margin-top 2px
    margin-left 0.5em
    border-radius 1em

    input[type="checkbox"]
      position absolute
      top 0
      right 0
      bottom 0
      left 0
      width 100%
      height 100%
      margin 0
      padding 0
      opacity 0

    &:before
      content ''
      position absolute
      top 0
      left 0
      height 1em
      width 1em
      border-radius 50%
      background-color color-white

    &.focused
      outline brand-color-2 auto 5px

    &.checked
      background-color brand-color-2
      border-color brand-color-2

      &:before
        left 1em

</style>
