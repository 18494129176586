export default function customDropDown() {
  var realDropdown = this;
  var dropdownTyping = {
    search: '',
    timeout: null,
  };
  var data = [];
  var selected;
  var customId;

  if (!realDropdown.is('select')) {
    return;
  }

  realDropdown.children('option').each(function () {
    var self = $(this);
    var classAttr = self.attr('class');
    var item = {
      val: self.val(),
      text: self.text(),
      selected: false,
      classes: classAttr ? classAttr : null,
    };

    if (!selected) {
      selected = item;
    }

    if (self.is(':selected')) {
      selected = item;
      item.selected = true;
    }
    if (item.val != '?') {
      data.push(item);
    }
  });

  customId = realDropdown.data('custom-id');
  if (customId) {
    $('[data-custom-rel="' + customId + '"]').remove();
    realDropdown.removeClass('hiddenButPresent');
  }

  var cssProps = ['vertical-align', 'float', 'display'];

  var cont = $('<div/>').addClass('customDropdown').css(realDropdown.css(cssProps)).addClass(realDropdown.attr('class'));

  if (realDropdown.attr('data-mr-ass')) {
    cont.attr('data-mr-ass', realDropdown.attr('data-mr-ass'));
  }

  if (cont.css('min-width') === '0px') {
    cont.css('min-width', realDropdown.outerWidth());
  }

  if (realDropdown.is(':disabled')) {
    cont.addClass('disabled');
  }

  realDropdown.addClass('hiddenButPresent');

  customId = new Date().getTime().toString(36);
  realDropdown.data('custom-id', customId);
  // Since this isn't real we have to set it as an attribute and not use data... for reasons?
  cont.attr('data-custom-rel', customId);
  if (!selected) {
    selected = {};
  }
  var select = $('<div/>')
    .addClass('select')
    .append($('<div/>').html(selected.text).addClass(selected.classes), $('<div/>').addClass('arrow down'));
  var options = $('<ul/>').addClass('option');
  options.attr('body-scroll-lock-ignore', '');
  options.attr('aria-hidden', true);

  if (realDropdown.attr)
    $.each(data, function () {
      var opt = $('<li/>').data('val', this.val).html(this.text).attr('data-mr-ass', this.val);

      if (this.selected) {
        opt.addClass('selected');
      }

      if (this.classes) {
        opt.addClass(this.classes);
      }

      options.append(opt);
    });

  select.mousedown(function () {
    select.data('mousedown', true);
  });

  select.mouseup(function () {
    select.data('mousedown', false);
  });

  select.focus(function () {
    if (!select.data('mousedown')) {
      select.click();
    }
  });

  select.on('click', function (e) {
    e.stopPropagation();

    if (realDropdown.is(':disabled')) {
      return;
    }

    if (options.is('.expanded')) {
      contractOptions();
    } else {
      expandOptions();
    }
  });

  realDropdown.change(function () {
    var sel = $(this).children(':selected');

    select.children('div:first').html(sel.text());

    options.children('li').removeClass('selected');

    options.children('li').each(function () {
      var li = $(this);

      if (li.data('val') == sel.val()) {
        li.addClass('selected');
        return false;
      }
    });
    contractOptions();
  });

  cont.append(select).append(options);

  realDropdown.after(cont);

  $('#container :input, #container .customDropdown > .select')
    .not('.hide')
    .each(function (i) {
      $(this).attr('tabindex', i + 1);
      if ($(this).is('.select')) {
        $(':input[tabindex="' + (i + 1) + '"]').removeAttr('tabindex');
      }
    });

  function keyPress(e) {
    var current = options.children('li.selected');
    var selected;

    // Special control keys
    switch (e.which) {
      case 27: // Escape
        contractOptions();
        break;

      case 9: // Tab
      case 13: // Return
        triggerOptionSelect();
        contractOptions();
        break;

      case 38: // Up
        selected = current.prev();
        if (selected.length === 0) {
          selected = options.children('li').last();
        }
        break;

      case 40: // Down
        selected = current.next();
        if (selected.length === 0) {
          selected = options.children('li').first();
        }
        break;
    }

    // Detect typing
    if (!selected && e.which >= 48 && e.which <= 90) {
      clearTimeout(dropdownTyping.timeout);
      dropdownTyping.search += String.fromCharCode(e.which);
      dropdownTyping.timeout = setTimeout(clearTextSearch, 600);
      selected = searchOptions();
    }

    if (selected) {
      selectOption(selected);
    }
  }

  function selectOption(elem) {
    elem.addClass('selected').siblings().removeClass('selected');

    options.scrollTop(0).scrollTop(elem.position().top);
  }

  function clearTextSearch() {
    clearTimeout(dropdownTyping.timeout);
    dropdownTyping.search = '';
  }

  function searchOptions() {
    var search = new RegExp('^' + dropdownTyping.search, 'i');

    // We'd use filter here if it was supported in more browsers.
    var selected;
    options.children('li').each(function () {
      if (search.test($(this).text())) {
        selected = $(this);
        return false;
      }
    });
    return selected;
  }

  function stopScroll(e) {
    if (e.which == 40 || e.which == 38) {
      e.preventDefault();
      return false;
    }
  }

  function triggerOptionSelect() {
    var selected = options.children('li.selected');
    realDropdown.val(selected.data('val')).change();
  }

  function contractOptions() {
    if (realDropdown.is(':disabled')) {
      return;
    }

    select.off('keydown', keyPress);
    $(window).off('keydown', stopScroll);

    var arrow = options.parents('.customDropdown').find('.arrow');
    $('body').off('click');

    arrow.removeClass('up').addClass('down');

    select.removeClass('expanded');
    options.removeClass('expanded');
    options.attr('aria-hidden', true);
  }

  function expandOptions() {
    if (realDropdown.is(':disabled')) {
      return;
    }

    select.on('keydown', keyPress);
    $(window).on('keydown', stopScroll);

    var arrow = options.parents('.customDropdown').find('.arrow');
    arrow.addClass('up').removeClass('down');

    select.addClass('expanded');
    options.addClass('expanded');
    options.attr('aria-hidden', false);
    $('body')
      .off('click')
      .on('click', function () {
        contractOptions();
      });
    options
      .children()
      .off('mousedown')
      .on('mousedown', function (e) {
        e.stopPropagation();
        selectOption($(this));
        triggerOptionSelect();
      });
  }

  return cont;
}
