import mrApi from './mrApi';

export default {
  addToWaitlist,
  getWaitlists
};

export function addToWaitlist(params) {
  return mrApi.post('/api/waitlist/addToWaitlist', params);
}

export function getWaitlists() {
  return mrApi.get('/api/waitlist/getWaitlists');
}
