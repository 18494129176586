import mrApi from './mrApi';

export default {
  submitReview,
  getProductReviewsForPdp,
  getProductReviews,
  canUserReview,
  canUserReviewUrlKey,
  voteForReview,
  getSortByConstants,
  getCustomerReviewForProduct,
  getSortedPagedCategoryReviews,
  getReviewSummariesForCategories
};

export function submitReview(params) {
  return mrApi.post('/api/review/submitReview', params);
}

export function getProductReviewsForPdp(params) {
  return mrApi.get('/api/review/getProductReviewsForPdp', { params });
}

export function getProductReviews(params) {
  return mrApi.get('/api/contentReview/getProductReviews', { params });
}

export function canUserReview(params) {
  return mrApi.get('/api/contentReview/canUserReview', { params });
}

export function canUserReviewUrlKey(params) {
  return mrApi.get('/api/contentReview/canUserReviewUrlKey', { params });
}

export function voteForReview(params) {
  return mrApi.post('/api/contentReview/voteForReview', params);
}

export function getSortByConstants() {
  return mrApi.get('/api/review/getSortByConstants');
}

export function getCustomerReviewForProduct(params) {
  return mrApi.get('/api/review/getCustomerReviewForProduct', { params });
}

export function getSortedPagedCategoryReviews(params) {
  return mrApi.get('/api/review/getSortedPagedCategoryReviews', { params });
}

export function getReviewSummariesForCategories(params) {
  return mrApi.get('/api/review/getReviewSummariesForCategories', { params });
}
