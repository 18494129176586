<template lang='pug'>
  .touch(:class="{swiped: swiped, up: swiped == 'up', right: swiped == 'right', down: swiped == 'down', left: swiped == 'left'}")
    slot(:swiped="swiped" :x="x" :y="y")
</template>

<script>
  import detectIt from 'detect-it';

  export default {
    data() {
      return {
        offsetXStart: 0,
        x: 0,
        offsetXEnd: 0,
        offsetYStart: 0,
        y: 0,
        offsetYEnd: 0,
        swiped: '',
        swipes: 0,
        preventPropagation: false,
        stopUpdates: false
      }
    },

    props: {
      scrollTakeoverDirection: {
        type: String
      }
    },

    mounted() {
      var self = this;
      var passiveOptions = false;

      if (!self.scrollTakeoverDirection && detectIt.passiveEvents) {
        passiveOptions = { passive: true };
      }

      self.$el.addEventListener('touchstart', e => {
        if (self.stopUpdates) {
          return;
        }
        if (self.preventPropagation) {
          e.preventDefault();
        }
        // use this to check if preventDefault can be called
        e.preventDefaultEnabled = !detectIt.passiveEvents;

        self.offsetXStart = e.touches[0].pageX - e.touches[0].target.offsetLeft;
        self.offsetXEnd = self.offsetXStart;

        self.offsetYStart = e.touches[0].pageY - e.touches[0].target.offsetTop;
        self.offsetYEnd = self.offsetYStart;

        self.$emit('tstart', self.offsetXStart, self.offsetYStart, e);
      }, passiveOptions);

      self.$el.addEventListener('touchmove', e => {
        if (self.stopUpdates) {
          return;
        }
        if (self.preventPropagation) {
          e.preventDefault();
        }
        // use this to check if preventDefault can be called
        e.preventDefaultEnabled = !detectIt.passiveEvents;

        self.offsetXEnd = e.touches[0].pageX - e.touches[0].target.offsetLeft;
        self.offsetYEnd = e.touches[0].pageY - e.touches[0].target.offsetTop;
        self.x = self.offsetXEnd - self.offsetXStart;
        self.y = self.offsetYEnd - self.offsetYStart;

        if ( (self.scrollTakeoverDirection == 'horizontal' && Math.abs(self.x) > 10) || (self.scrollTakeoverDirection == 'vertical' && Math.abs(self.y) > 10) ) {
          self.preventPropagation = true;
          e.preventDefault();
        } else if ( !self.preventPropagation && ((self.scrollTakeoverDirection == 'horizontal' && Math.abs(self.y) > 10) || (self.scrollTakeoverDirection == 'vertical' && Math.abs(self.x) > 10)) ) {
          self.stopUpdates = true;
        }

        self.$emit('tmove', {
          x: self.x,
          y: self.y
        }, e);
      }, passiveOptions);

      self.$el.addEventListener('touchend', e => {
        if (self.stopUpdates) {
          self.stopUpdates = false;
          return;
        }
        if (self.preventPropagation) {
          e.preventDefault();
        }
        // use this to check if preventDefault can be called
        e.preventDefaultEnabled = !detectIt.passiveEvents;

        var direction = null;
        var distance = 0;
        
        if (self.offsetXStart - self.offsetXEnd > 10) {
          direction = 'left';
          distance = self.offsetXStart - self.offsetXEnd;
        }
        if (self.offsetXStart - self.offsetXEnd < -10) {
          direction = 'right';
          distance = self.offsetXEnd - self.offsetXStart;
        }
        if (self.offsetYStart - self.offsetYEnd > 10 && self.offsetYStart - self.offsetYEnd > distance) {
          direction = 'up';
          distance = self.offsetYStart - self.offsetYEnd;
        }
        if (self.offsetYStart - self.offsetYEnd < -10 && self.offsetYEnd - self.offsetYStart > distance) {
          direction = 'down';
          distance = self.offsetYEnd - self.offsetYStart;
        }

        if (direction) {
          self.swiped = direction;
          self.swipes += 1;
          self.$emit(direction, distance, e);
          self.$emit('swiped', { direction, distance }, e);
        }

        self.x = 0;
        self.y = 0;

        self.preventPropagation = false;

        self.$emit('tend', {
          direction,
          distance, 
          offsetXEnd: self.offsetXEnd,
          offsetYEnd: self.offsetYEnd
        }, e);
      }, passiveOptions);
    }
  }
</script>
