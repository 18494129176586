<template lang="pug">
  div
    slot(:location="location" :limitless-pro-plus="limitlessProPlus" :is-limitless="isLimitless" :has-founder-offer="hasFounderOffer" :add-urm-to-cart="addUrmToCart" :adding-to-cart="addingToCart")
</template>

<script>
  import { mapState, mapGetters, mapActions } from 'vuex';

  export default {
    name: "hcbLocation",

    inject: ['getObjProperty'],

    props: {
      code: {
        type: String
      }
    },

    data() {
      return {
        addingToCart: false
      };
    },

    computed: {
      ...mapState('colorbar', ['location']),
      ...mapGetters('colorbar', ['hasFounderOffer']),

      ...mapState('urm', ['urmProduct']),

      ...mapState('constants', ['limitless']),

      ...mapGetters('customer', ['limitlessProPlus', 'limitlessPlus', 'limitlessPro',]),

      isLimitless() {
        return this.limitlessPlus || this.limitlessPro || this.limitlessProPlus;
      },
    },

    methods: {
      ...mapActions('colorbar', ['loadLocation']),
      ...mapActions('cart', ['addMembershipToCart']),
      ...mapActions('urm', ['getUrmProduct']),
      ...mapActions(['notifyError']),

      async addUrmToCart(eventPrefix) {
        if (this.addingtoCart) {
          return;
        }

        this.addingtoCart = true;

        if (eventPrefix) {
          if (this.isLimitless){
            this.trackMREvent(`${eventPrefix} - Limitless Pro+ Upgrade Clicked`, { location: this.location.code });
          } else {
            this.trackMREvent(`${eventPrefix} - Limitless Pro+ Purchase Clicked`, { location: this.location.code });
          }
        }

        const payload = {
          productId: this.urmProduct.id,
          qty: 1,
          subscription: 4,
          locationId: this.location._id,
        };

        try {
          await this.addMembershipToCart(payload);
          this.addingtoCart = false;
          window.location = '/cart';
        } catch (err) {
          this.addingtoCart = false;
          let errorsToNotify = {
            DUPLICATE_URM_SUBSCRIPTION: `Oops, it looks like you are already a member of ${this.limitless.PRO_PLUS}`,
          };

          let selectedErrorToNotify = errorsToNotify[this.getObjProperty(err, 'response.data.code')];

          if (selectedErrorToNotify) {
            this.notifyError(selectedErrorToNotify);
          } else {
            this.notifyError(err);
          }
        }
      },
    },

    created() {
      if (this.code) {
        this.loadLocation(this.code);
        this.getUrmProduct();
      }
    }
  };
</script>

<style scoped lang="stylus">
</style>
