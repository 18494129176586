import mrApi from './mrApi';

export default {
  getPreOrderId,
  cancelPreOrder
};

export function getPreOrderId(params) {
  return mrApi.get('/api/preOrder/getPreOrderId', { params });
}

export function cancelPreOrder(payload) {
  return mrApi.post('/api/preOrder/cancelPreOrder', payload);
}