import mrApi from './mrApi';

export default {
  getClientToken,
  updatePaypalInfo,
  updateApplePayInfo,
  getPaymentProfiles,
  savePaymentProfile,
  deletePaymentProfile,
  setDefaultPaymentProfile,
  addVideoChatPaymentMethod,
};

export function getClientToken() {
  return mrApi.get('/api/paymentProfile/getClientToken');
}

export function updatePaypalInfo(params) {
  return mrApi.post('/api/customer/updatePaypalInfo', params);
}

export function updateApplePayInfo(params) {
  return mrApi.post('/api/customer/updateApplePayInfo', params);
}

export function getPaymentProfiles() {
  return mrApi.get('/api/paymentProfile/getActivePaymentProfiles');
}

export function savePaymentProfile(params) {
  return mrApi.post('/api/customer/updateBillingInfo', params);
}

export function addVideoChatPaymentMethod(params) {
  return mrApi.post('/api/customer/addVideoChatPaymentMethod', params);
}

export function deletePaymentProfile(params) {
  return mrApi.post('/api/customer/deletePaymentProfile',  params);
}

export function setDefaultPaymentProfile(params) {
  return mrApi.post('/api/customer/setDefaultPaymentMethod', params);
}
