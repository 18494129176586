<template lang='pug'>
  .go-to-path
</template>

<script>
  export default {
    props: {
      path: String,
      newTab: {
        type: Boolean,
        default: false
      }
    },

    mounted() {
      this.goToPath(this.path, this.newTab);
    }
  }
</script>
