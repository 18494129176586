const state = {
  selectedColor: null
};

const mutations = {
  setSelectedColor(state, val) {
    state.selectedColor = Object.assign({}, val);
  }
};

const actions = {
  openPlaygroundModal({ dispatch }, { props = {} }) {
    const modalParams = {
      props: {
        viewOnModel: props.viewOnModel,
        product: props.product,
      },
      component: 'ColorSelectPlaygroundModal',
      theme: 'try-it-on-modal'
    };
    dispatch('modal/showModal', modalParams, { root: true });
  }
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
