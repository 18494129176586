<template lang="pug">
  .top-menu(:style="{ height: navHeight + 'px'}")
    nav.bg-gray-light(ref="container" :class="{ fixed: navFixed, up: navHidden }")
      ul(ref="bar").xs-f-xsmall.lg-f-small.max-at-tweak
        li(v-for="(item, index) in navItems" :key="item.url + index" :class="{selected: index == activeIndex}")
          button.btn-reset(
            v-if="item.method"
            @click="item.method()"
            :id="item.id"
          ) {{ item.text }}

          a.text-color-1(
            v-else-if="item.out || allOut"
            :href="item.url"
            :data-mr-ass="item.text"
            :class="{ 'semi-bold': index == activeIndex }"
          ) {{ item.text }}

          router-link.text-color-1(
            v-else
            exact
            :to="item.url"
            ref="button"
            :class="{ 'semi-bold': index == activeIndex }"
            :data-mr-ass="item.text"
          ) {{ item.text }}

</template>

<script>

  import { mapState, mapMutations } from 'vuex';

  export default {
    name: "TopMenu",

    inject: ['getObjProperty'],

    props: {

      //- used when we want all the navItems to reload the page
      allOut: {
        type: Boolean,
        default: false
      }
    },

    data() {
      return {
        isMounted: false,
        isOverflowing: false,
        scrolling: false,
        midPoint: 0,
        targetLocation: 0,
        targetWidth: 0,
        containerLeft: 0,
        resizeTimeout: 0,
        widths: {
          bar: 0,
          scroll: 0
        },
      };
    },

    computed: {
      ...mapState('global', ['windowData']),
      ...mapState('siteNav', ['navItems']),

      activeIndex() {
        var activeUrl = '';
        var active = -1;
        if (this.isMounted) {
          this.navItems.forEach((item, index) => {
            if (this.$route.path.includes(item.url) && item.url.length > activeUrl.length) {
              activeUrl = item.url;
              active = index;
              var activeTab = this.$refs.bar.children[active];
              this.targetWidth = activeTab.getBoundingClientRect().width;
              this.targetLocation = activeTab.getBoundingClientRect().x;
              this.containerLeft = this.$refs.container.getBoundingClientRect().x;
              this.barLeft = this.$refs.bar.getBoundingClientRect().x;
              this.scrollInToView();
            }
          });
        }

        return active;
      },

      navHeight() {
        return this.isMounted ? Math.ceil(this.$refs.container.getBoundingClientRect().height) : 0;
      },

      wrapperTop() {
        return this.isMounted ? window.pageYOffset + this.$el.getBoundingClientRect().top : 0;
      },

      wrapperBottom() {
        return this.isMounted ? this.wrapperTop + this.navHeight : 0;
      },

      navFixed() {
        return this.isMounted && this.windowData.scrollTop > this.wrapperTop;
      },

      navHidden() {
        return this.navFixed && this.windowData.scrollTop > this.wrapperBottom + 15 && this.windowData.scrollDirection == 1;
      },

      sliderHidden() {
        return this.activeIndex == 0 && !this.$route.path.includes(this.navItems[0].url);
      },
    },

    watch: {
      navItems() {
        //- when ever we add new item, ensure everything gets sized up
        this.$nextTick(() => {
          this.setWidths();
        });
      },
    },

    destroyed() {
      window.removeEventListener("resize", this.setWidths);
    },

    mounted() {
      this.isMounted = true;

      window.addEventListener("resize", () => {
        clearTimeout(this.resizeTimeout);
        this.resizeTimeout = setTimeout(this.initActiveNavItem, 200);
      });

      this.$nextTick(function () {
        this.initActiveNavItem();
      });
    },

    methods: {
      ...mapMutations('global', ['setShowNavs']),

      initActiveNavItem() {
        this.setWidths();
      },

      setWidths() {
        if (!this.getObjProperty(this, '$refs.bar')) {
          return;
        }

        const bar = this.$refs.bar.clientWidth || 0;
        const scroll = this.$refs.bar.scrollWidth || 0;

        this.widths = { bar, scroll };
        this.midPoint = bar / 2;

        this.setOverflow();
      },

      setOverflow() {
        this.isOverflowing = this.widths.bar < this.widths.scroll;
      },

      scrollInToView() {
        let halfTarget = this.targetWidth / 2;
        let offset = (this.targetLocation - this.midPoint) + halfTarget;
        var counter = 0;
        var steps = 40;

        if (!this.isOverflowing) {
          return;
        }

        offset = offset / steps;
        var sI = setInterval(() => {
          this.$refs.container.scrollLeft += offset;
          counter++;
          if (counter > steps) {
            clearInterval(sI);
          }
        }, 10);
      },
    }
  };
</script>

<style scoped lang="stylus">
  .top-menu
    li
      border-bottom 3px solid transparent

      &.selected
        border-bottom 3px solid brand-color-2

</style>