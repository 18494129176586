let open = false;
let span = document.createElement('span');
span.className = "underline clickable";

export default {
  bind: (el, binding) => {

    let fullText = el.textContent;
    let shortText = truncateString(fullText, binding.value.characters);

    //- set the initial text
    el.textContent = shortText;
    insertSpan(el);

    el.addEventListener('click', () => {
      open = !open;
      el.textContent = open ? fullText : shortText;
      insertSpan(el);
    });
  }
};

function spanText() {
  return open ? 'Show less' : 'Show more';
}

function insertSpan(el) {
  span.innerHTML = spanText();
  el.appendChild(span);
}

function truncateString(str, num) {
  if (num > str.length){
    return str;
  } else {
    str = str.substring(0, num);
    return str + " ";
  }

}