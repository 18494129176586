document.addEventListener('click', function(event) {
  if (!event.target.matches('.mr-btn') && !event.target.matches('.ripple-btn')) {
    return;
  }

  const btn = event.target;
  const rect = event.target.getBoundingClientRect();

  let btnOffset = {
    top: rect.top + window.scrollY,
    left: rect.left + window.scrollX
  };

  const x = event.pageX - btnOffset.left;
  const y = event.pageY - btnOffset.top;

  const duration = 600;
  let animationFrame, animationStart;

  const animationStep = function(timestamp) {
    if (!animationStart) {
      animationStart = timestamp;
    }

    let frame = timestamp - animationStart;
    if (frame < duration) {
      let easing = (frame/duration) * (2 - (frame/duration));

      let circle = "circle at " + x + "px " + y + "px";
      let color = "rgba(0, 0, 0, " + (0.25 * (1 - easing)) + ")";
      let stop = 90 * easing + "%";

      btn.style.backgroundImage = "radial-gradient(" + circle + ", " + color + " " + stop + ", transparent " + stop + ")";

      animationFrame = window.requestAnimationFrame(animationStep);
    } else {
      btn.style.backgroundImage = "none";

      window.cancelAnimationFrame(animationFrame);
    }
  };

  animationFrame = window.requestAnimationFrame(animationStep);
}, false);
