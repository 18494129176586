import mrApi from './mrApi';

export default {
  getSubscriptions,
  updateSubscription,
  updateSubscriptionColor,
  updateColorPlusSubscriptionColor,
  cancelSubscription,
  updateShipAddressForSubscriptions,
  updatePaymentProfileForSubscriptions,
  getSubDateOptions,
  getSubPauseDates,
  getCancelReasons,
  getLastDeliveredInvoiceForSub,
  getAvailabilityByRegionForSubscription,
  getCustomerNextSubByProductType,
  getAmountSaved
};

export function getSubscriptions(params) {
  return mrApi.get('/api/subscription/getSubscriptionsByCustomerId', params);
}

export function updateSubscription(params) {
  return mrApi.post('/api/subscription/updateSubscription', params);
}

export function updateSubscriptionColor(params) {
  return mrApi.post('/api/subscription/updateSubscriptionColor', params);
}

export function updateColorPlusSubscriptionColor(params) {
  return mrApi.post('/api/subscription/updateColorPlusSubscriptionColor', params);
}

export function cancelSubscription(params) {
  return mrApi.post('/api/subscription/cancelSubscription', params);
}

export function updateShipAddressForSubscriptions(params) {
  return mrApi.post('/api/subscription/updateShipAddressForSubscriptions', params);
}

export function updatePaymentProfileForSubscriptions(params) {
  return mrApi.post('/api/subscription/updatePaymentProfileForSubscriptions', params);
}

export function getSubDateOptions(params) {
  return mrApi.get('/api/subscription/getSubDateOptions', { params });
}

export function getSubPauseDates(params) {
  return mrApi.get('/api/subscription/getSubPauseDates', { params });
}

export function getCancelReasons(params) {
  return mrApi.get('/api/subscription/getCancelReasons', { params });
}

export function getLastDeliveredInvoiceForSub(params) {
  return mrApi.get('/api/subscription/getLastDeliveredInvoiceForSub', { params });
}

export function getAvailabilityByRegionForSubscription(params) {
  return mrApi.get('/api/subscription/getAvailabilityByRegionForSubscription', { params });
}

export function getCustomerNextSubByProductType(params) {
  return mrApi.get('/api/subscription/getCustomerNextSubByProductType', { params });
}

export function getAmountSaved(params) {
  return mrApi.get('/api/subscription/getAmountSaved', { params });
}