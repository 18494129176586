module.exports = {
  invoiceItemTags: {
    sub: 'SUBSCRIPTION',
    sub_addon: 'SUBSCRIPTION ADD-ON'
  },
  invoiceOrderStatus: {
    PENDING: 'pending',
    RESHIPPED: 'reshipped',
    PROCESSING: 'processing',
    DECLINED: 'declined',
    CANCELED: 'canceled',
    SHIPPED: 'shipped',
    RETURNED: 'returned',
    COMPLETED: 'completed',
    SCHEDULED: 'scheduled',
    BACKORDERED: 'backordered',
    DELIVERED: 'delivered',
    MODIFIED: 'modified' // Not actually written to the order_status column, just used for history
  },
  colorSolutions: {
    customerPromos: {
      freeColorKit: 2849,
      freeShipping: 2858,
      freeBowlAndBrush: 2932,
      freePrime: 2933
    }
  },
  subscriptionStatus: {
    ACTIVE: 'active',
    CANCELED: 'canceled',
  }
};