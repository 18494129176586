<template lang='pug'>
  .modal
    slot(v-bind:show="show" v-bind:opened-by-cookie="openedByCookie" v-bind:open="open" v-bind:close="close" v-bind:toggle="toggle")
</template>

<script>
  export default {
    props: {
      cookie: String
    },

    data() {
      return {
        show: false,
        openedByCookie: false
      }
    },

    methods: {
      open() {
        this.show = true;
        this.$emit('opened');
      },

      close() {
        this.show = false;
        this.openedByCookie = false;
        this.$emit('closed');
      },

      toggle() {
        this.show = !this.show;
        if (this.show) {
          this.$emit('opened');
        } else {
          this.$emit('closed');
        }
      }
    },

    mounted() {
      if (!this.$cookies.get(this.cookie)) {
        this.open();
        this.openedByCookie = true;
        this.$cookies.set(this.cookie, true, '7d');
      }
    }
  }
</script>
