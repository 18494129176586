const Services = () => import(/* webpackChunkName: "HairColorBarBookingServices" */ './Services');
const Addons = () => import(/* webpackChunkName: "HairColorBarBookingAddons" */ './Addons');
const HCBCalendarSplitter = () => import(/* webpackChunkName: "HairColorBarBookingCalendar" */ './HCBCalendarV2/ExperimentSplitter');
const Info = () => import(/* webpackChunkName: "HairColorBarBookingInfo" */ './Info');
const Appointments = () => import(/* webpackChunkName: "HairColorBarBookingAppointments" */ './Appointments');
const Confirmation = () => import(/* webpackChunkName: "HairColorBarBookingConfirmation" */ './Confirmation');

export default [
  { name: 'booking-location', path: '/colorbar/booking/:code', redirect: '/colorbar/booking/:code/services' },
  { name: 'booking-services', path: '/colorbar/booking/:code/services', component: Services },
  { name: 'booking-addons', path: '/colorbar/booking/:code/addons', component: Addons },
  { name: 'booking-calendar', path: '/colorbar/booking/:code/calendar', component: HCBCalendarSplitter },
  { name: 'booking-info', path: '/colorbar/booking/:code/info', component: Info },
  { name: 'booking-confirmation', path: '/colorbar/booking/:code/confirmation', component: Confirmation },
  { name: 'booking-appointments', path: '/colorbar/booking/:code/appointments', component: Appointments },
  { name: 'booking-appointment-action', path: '/colorbar/booking/:code/appointments/:action/:appointmentId', component: Appointments },
  { path: '/colorbar/booking/*', beforeEnter: () => { window.location = '/colorbar'; } },
];
