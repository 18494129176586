import mrApi from './mrApi';

export function getAddToCalendarLinks(params) {
  return mrApi.get('/api/videoChat/getAddToCalendarLinks', { params });
}

export function getToken(params) {
  return mrApi.get('/api/videoChat/getToken', { params });
}

export function isHostConnected(params) {
  return mrApi.get('/api/videoChat/isHostConnected', { params });
}

export function isRoomValid(params) {
  return mrApi.get('/api/videoChat/isRoomValid', { params });
}

export function getAvailability(params) {
  return mrApi.get('/api/videoChat/getAvailability', { params });
}

export function getOnDemandAvailability(params) {
  return mrApi.get('/api/videoChat/getOnDemandAvailability', { params });
}

export function bookOnDemandConsultation(params) {
  return mrApi.post('/api/videoChat/bookOnDemandConsultation', params);
}

export function updateConsultationDetails(params) {
  return mrApi.post('/api/videoChat/updateConsultationDetails', params);
}

export function isBookingValid(params) {
  return mrApi.post('/api/videoChat/isBookingValid', params);
}

export function cancelBookingByCustomer(params) {
  return mrApi.post('/api/videoChat/cancelBookingByCustomer', params);
}

export function rescheduleBookingByCustomer(params) {
  return mrApi.post('/api/videoChat/rescheduleBookingByCustomer', params);
}

export function getConsultationReasons(params) {
  return mrApi.get('/api/videoChat/getConsultationReasons', { params });
}