import vueCustomerSvc from '@services/vueCustomerSvc';
import groupBy from 'lodash.groupby';
import { getObjProperty } from '@utilities/mrVueUtils';
import dayjs from '@dayjs';
import constants from './constants';

const memberProductTypes = ["color_kit", "colorwonder", "light_works", "root_reboot"];

export const state = {
  info: {},
  cdata: {},
  marketingPreferences: {},
  optInSmsTransactional: true,
  optInSmsMarketing: true,
  optInEmail: true,
  isMinor: false,
  customerEditForm: {},
  recentInvoices: [],
  recentInvoicesLoaded: false,
  recentColorOrder: {},
  loadingCustomer: false,
  loadingRecentInvoices: false,
  activeCustomerPromos: {},
  activeCustomerPromosLoaded: false,
  limitlessProPlusPerks: [],
  refreshingCdata: false,
  limitlessProPlusPerksLoaded: false,
  loadingLimitlessProPlusPerks: false,
};

export const getters = {
  isCustomerPresent(state) {
    const authenticated = getObjProperty(state, 'cdata.user.id');
    return !!authenticated;
  },

  isCustomerHardAuthenticated(state, getters) {
    const authenticated = getObjProperty(state, 'cdata.user.authed');
    return getters.isCustomerPresent && !!authenticated;
  },

  isCustomerSoftAuthenticated(state, getters) {
    return getters.isCustomerPresent && !getters.isCustomerHardAuthenticated;
  },

  isBookingInfoPresent(state) {
    return getObjProperty(state, 'cdata.name') && getObjProperty(state, 'cdata.email') && (getObjProperty(state, 'cdata.phone') || getObjProperty(state, 'cdata.phoneMobile'));
  },

  disableBirthday(state) {
    return Boolean(state.info.birthday_month && state.info.birthday_day);
  },

  boxesUsed(state) {
    let boxes = [];

    memberProductTypes.forEach(type => {
      if (state.cdata.purchaseSummaryByType && state.cdata.purchaseSummaryByType[type]) {
        boxes.push(state.cdata.purchaseSummaryByType[type].numPurchasedTotal);
      }
    });

    return boxes.reduce((sum, a) => sum + a, 0);
  },

  isColorSubscriber(state) {
    return memberProductTypes.some(type => state.cdata.activeSubTypes && state.cdata.activeSubTypes[type]);
  },

  activeColorKitSubscription(state) {
    return state.cdata.activeSubscriptions?.find(p => p.productType === 'color_kit');
  },

  recommendedRCCKit(state) {
    const activeSub = state.cdata.activeSubscriptions?.find(p => p.productType === 'color_kit');
    let recommendedKit = null;
    if (activeSub) {
      recommendedKit = { code: activeSub.productCode, id: activeSub.productId };
    } else if (state.cdata.lastColorKit) {
      recommendedKit = { code: state.cdata.lastColorKit.code, id: state.cdata.lastColorKit.product_id };
    } else if (state.cdata.bestColorMatch) {
      recommendedKit = state.cdata.bestColorMatch;
    }

    return recommendedKit;
  },

  onTheWaySubsMap(state) {
    const map = {};
    if (!state.recentInvoices.length) {
      return map;
    }
    state.recentInvoices.forEach(invoice => {
      invoice.items.forEach(item => {
        if (item.subscription_id && (invoice.order_status == 'pending' || invoice.order_status == 'processing' || invoice.order_status == 'shipped')) {
          map[item.subscription_id] = {
            status: invoice.order_status,
            trackingUrl: invoice.tracking_url
          };
        }
      });
    });

    return map;
  },

  colorSatisfactionInvoice(state) {
    let invoice;
    //- check if this type of invoice exists
    if (state.cdata.lastDeliveredColorInvoice && state.cdata.lastDeliveredColorInvoice.id) {
      invoice = state.cdata.lastDeliveredColorInvoice;
    } else {
      return {};
    }

    //- proceed only if colorSatisfaction has not been answered
    if (Object.prototype.hasOwnProperty.call(state.cdata, "colorSatisfactionScore") && state.cdata.colorSatisfactionScore > -1) {
      return {};
    }

    //- proceed only if this is their first color_kit order
    if (state.cdata.purchaseSummaryByType.color_kit.numPurchasedTotal > 1) {
      return {};
    }

    //- check if it has been 8 days since the delivery
    if (dayjs().diff(dayjs(invoice.dateDelivered), 'days') < 8) {
      return {};
    }

    //- check if it is more than 10 days before the next renewal order
    if (state.cdata.nextRefillDate && dayjs(state.cdata.nextRefillDate).diff(dayjs(), 'days') < 10) {
      return {};
    }

    return invoice;
  },

  productIdInPurchaseList: (state) => (id) => {
    return (state.cdata.purchasedProductIds || []).indexOf(id) >= 0;
  },

  isVariablePricingEnabledForCustomer(state, getters) {
    return (variablePricePromoApplyTo) => {
      if (variablePricePromoApplyTo?.newCustomers && !getters.isNewCustomer) {
        return false;
      }

      if (variablePricePromoApplyTo?.existingCustomers && !getters.isExistingCustomer) {
        return false;
      }

      if (variablePricePromoApplyTo?.nonMembers && getters.customerHasMemberships) {
        return false;
      }

      if (variablePricePromoApplyTo?.memberships && !getters.customerHasMemberships) {
        return false;
      }

      return true;
    };
  },

  isNewCustomer(state, getters) {
    return ['net new', 'return visitor'].includes(getters.visitorType);
  },

  isExistingCustomer(state, getters) {
    return getters.visitorType === 'return buyer';
  },

  visitorType(state) {
    if (!state?.cdata?.visitorType) {
      return '';
    }

    return String(state.cdata.visitorType).toLowerCase();
  },

  hasMembershipPricing(state) {
    return ['expanded_membership', 'expanded_membership_and_prices'].includes(state.cdata.priceGroup);
  },

  closestColorbar(state) {
    const cdataLocation = getObjProperty(state.cdata, 'closest_colorbar');
    const beautyProfileLocation = getObjProperty(state.cdata, 'beautyProfile.closest_colorbar');
    const contextLocation = getObjProperty(state.cdata, 'locationContext.closestColorbar');
    return cdataLocation || beautyProfileLocation || contextLocation;
  },

  limitlessProPlus(state, getters) {
    const proPlusSub = getters.getActiveSubscriptions.find(sub => sub.productCode === constants.state.limitlessCodes.PRO_PLUS);
    if (proPlusSub) {
      return proPlusSub;
    } else {
      const proPlus = getObjProperty(state, 'cdata.membershipDetails.limitlessProPlus');
      if (proPlus && (proPlus.isActive && proPlus.dormant && proPlus.pendingDowngrade)) {
        const proSub = getters.getActiveSubscriptions.find(sub => sub.productCode === constants.state.limitlessCodes.PRO) || {};

        return {
          locationCode: proSub.locationCode,// as it is a downgrade then the location for pro is always the same as the downgraded pro+
          subscriptionId: proSub.subscriptionId,// same as above, needed to cancel
          paymentId: proSub.paymentId,// same as above, needed to cancel
          productCode: constants.state.limitlessCodes.PRO_PLUS,
          productType: 'renewable_cb_membership',
          nextRefill: null,
        };
      }
    }
  },

  hasPendingDowngrade(state) {
    const proPlus = getObjProperty(state, 'cdata.membershipDetails.limitlessProPlus');
    if (proPlus && proPlus.pendingDowngrade) {
      return proPlus.endDate;
    } else {
      return false;
    }
  },

  limitlessPro(state, getters) {
    if (!getters.limitlessProPlus) {
      return getters.getActiveSubscriptions.find(sub => sub.productCode === constants.state.limitlessCodes.PRO);
    }
    return false;
  },

  limitlessPlus(state, getters) {
    return getters.getActiveSubscriptions.find(sub => sub.productCode === constants.state.limitlessCodes.PLUS);
  },

  colorPlusMembershipData(state) {
    let colorPlusData = state.cdata?.colorPlusMembershipDetails?.colorPlusMembershipData;
    let colorPlusProductId = state.cdata?.activeSubscriptions?.find(sub => sub.productType === constants.state.limitlessCodes.COLOR_PLUS)?.productId;

    if (colorPlusProductId) {
      colorPlusData.productId = colorPlusProductId;
    }

    return colorPlusData;
  },

  isColorPlusSubscriptionActive(state) {
    return state.cdata?.membershipDetails?.colorPlus?.isActive || false;
  },

  getActiveSubscriptions(state) {
    return getObjProperty(state, 'cdata.activeSubscriptions') || [];
  },

  customerMemberships(state, getters) {
    return {
      plus: getters.limitlessPlus,
      pro: getters.limitlessPro,
      proPlus: getters.limitlessProPlus,
      colorPlus: getters.colorPlusMembershipData
    };
  },

  customerHasMemberships(state, getters) {
    return !Object.values(getters.customerMemberships).filter(Boolean).length == 0;
  },

  customerInfoBirthday(state) {
    if (state.info.birthday_month && state.info.birthday_day) {
      return `${state.info.birthday_month}-${state.info.birthday_day}-${dayjs().year()}`;
    }
  },

  customerHasHadAnAppointment(state) {
    return !!getObjProperty(state, 'cdata.lastAppointment.locationCode');
  },

  isOptInSmsTransactional(state) {
    // by default always opt in
    let optInSmsTransactional = true;
    // if the customer has a preference then use that
    if (Object.hasOwnProperty.call(state.marketingPreferences, 'optInSmsTransactional')) {
      optInSmsTransactional = !!state.marketingPreferences.optInSmsTransactional;
    }
    return optInSmsTransactional;
  },

  originalOptInSmsTransactional(state) {
    if (Object.hasOwnProperty.call(state.marketingPreferences, 'optInSmsTransactional')) {
      return !!state.marketingPreferences.optInSmsTransactional;
    }
    return false;
  },

  originalOptInSmsMarketing(state) {
    if (Object.hasOwnProperty.call(state.marketingPreferences, 'optInSmsMarketing')) {
      return !!state.marketingPreferences.optInSmsMarketing;
    }
    return false;
  },

  isOptInSmsMarketing(state) {
    // by default always opt in
    let optInSmsMarketing = true;
    // if the customer has a preference then use that
    if (Object.hasOwnProperty.call(state.marketingPreferences, 'optInSmsMarketing')) {
      optInSmsMarketing = !!state.marketingPreferences.optInSmsMarketing;
    }
    return optInSmsMarketing;
  },

  isOptInEmail(state) {
    // by default always opt in
    let optInEmail = true;
    // if the customer has a preference then use that
    if (Object.hasOwnProperty.call(state.marketingPreferences, 'optInEmail')) {
      optInEmail = !!state.marketingPreferences.optInEmail;
    }
    return optInEmail;
  },

  creditBalance(state) {
    return getObjProperty(state, 'cdata.creditBalance');
  },

  lastOneTimeOrder(state) {
    return getObjProperty(state, 'cdata.lastOneTimeOrder') || {};
  },

  packageDeals(state) {
    return getObjProperty(state, 'cdata.packageDealsList');
  },

  groupedPackageDeals(state, getters) {
    const deals = getters.packageDeals;
    return deals;
  },
};

export const actions = {
  loadCustomer({ commit, dispatch }) {
    commit('setLoadingCustomer', true);

    return vueCustomerSvc.loadCustomer()
      .then(res => {
        commit('setCustomerInfo', res.data);
        commit('setCustomerEditForm', res.data);
        dispatch('normalizeMarketingPreferences', res.data.marketingPreferences);
        commit('setLoadingCustomer', false);
      }).catch(() => commit('setLoadingCustomer', false));
  },

  async refreshCustomerCdata({ commit }) {
    if (state.refreshingCdata) {
      return;
    }

    commit('setRefreshingCdata', true);

    try {
      const { data } = await vueCustomerSvc.getCustomerCdata();
      commit('setCdata', data);
      commit('setRefreshingCdata', false);
      return data;
    } catch (err) {
      commit('setRefreshingCdata', false);
    }
  },

  updateCustomer({ dispatch, state }) {
    vueCustomerSvc.updateCustomer(state.customerEditForm)
      .then(res => {
        const validation = Object.values(res?.data?.validation || {});
        if (validation.length) {
          validation.forEach(err => {
            dispatch('notifyError', err, { root: true });
          });
          return;
        }

        dispatch('notifySuccess', 'Your account has been updated.', { root: true });

        if (res.sendSMSOptIn) {
          dispatch('notifyInfo', "You've opted-in to receive SMS messages. Check your phone to complete the process");
        }

        dispatch('loadCustomer');
      })
      .catch(err => {
        // this.$root.checkUserLoginError(err);

        var error = 'We were not able to update your info. Please try again or contact Color Crew';
        if (err.data && err.data.message) {
          error = err.data.message;
        }

        dispatch('notifyError', error, { root: true });
      });
  },

  setMarketingPreferences({ dispatch }, payload) {
    return vueCustomerSvc.setMarketingPreferences(payload).then(() => {
      dispatch('getMarketingPreferences');
      dispatch('notifySuccess', 'Your marketing preferences have been updated.', { root: true });
    }).catch(() => {
      dispatch('notifyError', null, { root: true });
    });
  },

  async updateSmsPreferencesForGuest({ dispatch, state }, payload) {
    try {
      await vueCustomerSvc.updateMarketingPreferencesForGuest({
        phone: payload.mobileNumber,
        marketingPreferences: {
          optInSmsTransactional: !!payload.optInSmsTransactional,
          optInSmsMarketing: !!payload.optInSmsMarketing,
        }
      });

      await dispatch('getMarketingPreferences');

      let subscribedToSms = state.marketingPreferences.optInSmsTransactional || state.marketingPreferences.optInSmsMarketing;
      if (subscribedToSms) {
        let message = 'You have subscribed to order updates & reminders via SMS. Check your phone to complete the process.';
        dispatch('notifySuccess', message, { root: true });
      }

    } catch (err) {
      dispatch('notifyError', 'We were not able to update your SMS preferences', { root: true });
    }
  },

  async updateSmsPreferences({ dispatch, state }, payload) {
    try {
      await vueCustomerSvc.setMarketingPreferences({
        email: payload.email,
        marketingPreferences: {
          optInEmail: state.marketingPreferences.optInEmail,
          optInSmsTransactional: payload.optInSmsTransactional === undefined ? state.marketingPreferences.optInSmsTransactional : !!payload.optInSmsTransactional,
          optInSmsMarketing: payload.optInSmsMarketing === undefined ? state.marketingPreferences.optInSmsMarketing : !!payload.optInSmsMarketing,
        },
      });
      dispatch('getMarketingPreferences');

      if (payload.mobileNumber || (state.info.id && !state.info.phone_mobile)) {
        await vueCustomerSvc.updateCustomer({ phone_mobile: payload.mobileNumber || state.info.marketingPreferences.mobile_number });
      }
      let subscribedToSms = state.marketingPreferences.optInSmsTransactional || state.marketingPreferences.optInSmsMarketing;
      var message = `You have ${subscribedToSms ? 'subscribed' : 'unsubscribed'} to order updates & reminders via SMS.`;
      if (subscribedToSms) {
        message += ' Check your phone to complete the process.';
      }

      dispatch('notifySuccess', message, { root: true });
    } catch (err) {
      dispatch('notifyError', 'We were not able to update your SMS preferences', { root: true });
    }
  },

  getMarketingPreferences({ dispatch }) {
    return vueCustomerSvc.getMarketingPreferences()
      .then(res => {
        dispatch('normalizeMarketingPreferences', res.data);
      });
    //- ignoring error
  },

  initRecentInvoices({ dispatch, state }) {
    if (!state.recentInvoicesLoaded) {
      dispatch('loadRecentInvoices');
    }
  },

  loadRecentInvoices({ state, commit }) {
    if (state.loadingRecentInvoices) {
      return;
    }

    commit('setLoadingRecentInvoices', true);
    return new Promise((resolve, reject) => {
      vueCustomerSvc.getCustomerRecentInvoices().then(res => {
        commit('setRecentInvoices', getObjProperty(res, 'data.recentInvoices'));
        commit('setRecentColorOrder', getObjProperty(res, 'data.recentColorOrder.invoice'));
        commit('setRecentInvoicesLoaded');
        resolve();
      }).catch(reject).finally(() => commit('setLoadingRecentInvoices', false));
    });
  },

  initLimitlessProPlusPerks({ dispatch, state }) {
    if (!state.limitlessProPlusPerksLoaded) {
      dispatch('loadLimitlessProPlusPerksLoaded');
    }
  },

  loadLimitlessProPlusPerksLoaded({ state, commit, getters }) {
    if (state.loadingLimitlessProPlusPerks) {
      return;
    }

    commit('setLoadingLimitlessProPlusPerks', true);
    return new Promise((resolve, reject) => {
      vueCustomerSvc.getCustomerLimitlessProPlusPerks({ locationCode: getters.limitlessProPlus.locationCode }).then(res => {
        commit('setLimitlessProPlusPerks', getObjProperty(res, 'data'));
        commit('setLimitlessProPlusPerksLoaded');
        resolve();
      }).catch(reject).finally(() => commit('setLoadingLimitlessProPlusPerks', false));
    });
  },

  normalizeMarketingPreferences({ commit }, val) {
    if (!val) {
      return;
    }
    //- need to normalize marketingPreference object for vuex consumption
    var prefs = {};

    if (val) {
      prefs.unsub = val.unsub;
      prefs.tips = val.messages.tips;
      prefs.promotions = val.messages.promotions;
      prefs.allMessageTypes = val.allMessageTypes;
      prefs.mobile_number = val.mobile_number;
      prefs.optInEmail = !(getObjProperty(val, 'iterable_unsub_channel') || []).some(element => element === 'marketing');
      prefs.optInSmsTransactional = !(getObjProperty(val, 'iterable_unsub_channel') || []).some(element => element === 'SMSTransactional');
      prefs.optInSmsMarketing = !(getObjProperty(val, 'iterable_unsub_channel') || []).some(element => element === 'SMSMarketing');
    }

    commit('setMarketingPreferences', prefs);
    commit('setOptInSmsTransactional', prefs.optInSmsTransactional);
    commit('setOptInSmsMarketing', prefs.optInSmsMarketing);
    commit('setOptInEmail', prefs.optInEmail);
  },

  updateBirthday({ commit, state }) {
    let birthday = null;
    if (state.customerEditForm.birthday_month && state.customerEditForm.birthday_day) {
      var month = ('0' + state.customerEditForm.birthday_month).slice(-2);
      var day = ('0' + state.customerEditForm.birthday_day).slice(-2);
      birthday = month + '/' + day + '/1896';
    }

    commit('setBirthday', birthday);
  },

  initActiveCustomerPromos({ dispatch, state }) {
    if (!state.activeCustomerPromosLoaded) {
      dispatch('loadActiveCustomerPromos');
    }
  },

  loadActiveCustomerPromos({ commit }) {
    return new Promise((resolve, reject) => {
      vueCustomerSvc.getAllCustomerPromos({ status: 'active' })
        .then(({ data = [] }) => {
          commit('setActiveCustomerPromos', groupBy(data, 'promo_id'));
          commit('setActiveCustomerPromosLoaded');
          resolve();
        }).catch(reject);
    });
  },

  checkHasPassword({ dispatch }, email) {
    return vueCustomerSvc.verifyEmailHasAssociatedPassword({ email })
      .then((res) => {
        if (res.data && res.data.exists === false) {
          dispatch('notifyError', 'There is no password linked to this account. Check your email for instructions to help create a password or click Forgot Password below.', { root: true });
        }
      });
  }
};

export const mutations = {
  setLoadingCustomer(state, val) {
    state.loadingCustomer = Boolean(val);
  },

  setCustomerInfo(state, val) {
    state.info = val;
  },

  setCustomerEditForm(state, val) {
    state.customerEditForm = Object.assign({}, val);
  },

  setMarketingPreferences(state, val) {
    state.marketingPreferences = val;
  },

  setOptInSmsTransactional(state, val) {
    state.optInSmsTransactional = Boolean(val);
  },

  setOptInSmsMarketing(state, val) {
    state.optInSmsMarketing = Boolean(val);
  },

  setOptInEmail(state, val) {
    state.optInEmail = Boolean(val);
  },

  setIsMinor(state, val) {
    state.isMinor = Boolean(val);
  },

  setEmailTips(state, val) {
    state.marketingPreferences.tips = val;
  },

  setEmailPromotions(state, val) {
    state.marketingPreferences.promotions = val;
  },

  setEmailUnsub(state, val) {
    state.marketingPreferences.unsub = val;
  },

  setFirstName(state, val) {
    state.customerEditForm.first_name = val;
  },

  setLastName(state, val) {
    state.customerEditForm.last_name = val;
  },

  setEmail(state, val) {
    state.customerEditForm.email = val;
  },

  setPhoneMain(state, val) {
    state.customerEditForm.phone_main = val;
  },

  setPhoneMobile(state, val) {
    state.customerEditForm.phone_mobile = val;
  },

  setBirthday(state, val) {
    state.customerEditForm.birthday = val;
  },

  setBirthdayDay(state, val) {
    state.customerEditForm.birthday_day = val;
  },

  setBirthdayMonth(state, val) {
    state.customerEditForm.birthday_month = val;
  },

  setCdata(state, val) {
    state.cdata = val;
  },

  setRefreshingCdata(state, val) {
    state.refreshingCdata = Boolean(val);
  },

  setRecentInvoices(state, val) {
    state.recentInvoices = val;
  },

  setLoadingRecentInvoices(state, val) {
    state.loadingRecentInvoices = Boolean(val);
  },

  setRecentInvoicesLoaded(state) {
    state.recentInvoicesLoaded = true;
  },

  setRecentColorOrder(state, val) {
    state.recentColorOrder = val;
  },

  setLimitlessProPlusPerks(state, val) {
    state.limitlessProPlusPerks = val;
  },

  setLoadingLimitlessProPlusPerks(state, val) {
    state.loadingLimitlessProPlusPerks = Boolean(val);
  },

  setUnlimitedRootsMembershipPerksLoaded(state) {
    state.unlimitedRootsMembershipPerksLoaded = true;
  },

  setActiveCustomerPromos(state, val) {
    state.activeCustomerPromos = val;
  },

  setActiveCustomerPromosLoaded(state) {
    state.activeCustomerPromosLoaded = true;
  },

  setLimitlessProPlusPerksLoaded(state) {
    state.limitlessProPlusPerksLoaded = true;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};