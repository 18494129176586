<template lang="pug">
  h3.bold.upper.xs-f-small.md-f-small.lg-f-large.max-at-tweak.section-title {{ text }}
    slot

</template>

<script>
  export default {
    name: "SectionTitle",
    props: {
      text: {
        type: String
      }
    }
  };
</script>
