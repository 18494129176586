<template lang="pug">
  .vue-dropdown(:class="{ 'raise-label': labelRaised, 'invalid': v.$error }" class="mr-dropdown")
    select(data-custom :id="id" :value="value" ref='dropdown' @change='handleInput' @focus="onFocus()" @blur="onBlur()" :disabled="disabled")
      slot
    label(:for="id") {{ label }}

    transition(name="fade")
      .input-error.xs-f-xsmall.lg-f-xxsmall.max-at-tweak(v-if="inputError")
        i.icon-warning
        | {{ inputError }}

</template>

<script>
  import customDropdown from './customDropdown';

  export default {
    name: 'Dropdown',

    props: {
      label: String,
      value: [Number, String],
      disabled: {
        type: Boolean,
        default: false,
        required: false,
      },
      v: {
        type: Object,
        default: () => ({}),
      },
    },

    data() {
      return {
        id: null,
        raiseLabel: false,
        changeWatcher: null,
      };
    },

    computed: {
      inputError() {
        var error = null;
        if (!this.v.$params) {
          return error;
        }

        if (this.v.$params.required && this.v.$error && !this.v.$model) {
          error = `This field is required.`;
        }
        return error;
      },

      labelRaised() {
        return this.value !== '' && this.value != undefined && this.value != null;
      },
    },

    watch: {
      value(newValue) {
        if (newValue) {
          this.dropdownInit();
        }
      },
    },

    mounted() {
      this.id = this._uid;

      if (!$.fn.customDropdown) {
        $.fn.customDropdown = customDropdown;
      }
      this.dropdownInit();
    },

    updated() {
      this.dropdownInit();
    },

    methods: {
      handleInput(e) {
        this.$emit('input', e.target.value);
      },

      onFocus() {
        var el = $(this.$refs.dropdown);
        el.parent().find('div.customDropdown')[0].classList.add('focused');
      },

      onBlur() {
        var el = $(this.$refs.dropdown);
        el.parent().find('div.customDropdown')[0].classList.remove('focused');
      },

      dropdownInit() {
        var el = $(this.$refs.dropdown);
        el.customDropdown();
        this.changeWatcher = this.changeWatcher || el.on('change', this.handleInput);
      },
    },
  };
</script>

<style lang="stylus">
  .vue-dropdown
    position relative
    display block
    margin 0.55em 0

    label
      position absolute
      top 12px
      left 1.1em
      z-index 1
      cursor text
      pointer-events none
      color brand-color-2
      font-size 14px
      line-height 1.3em

    .customDropdown
      width 100%

      .select
        line-height 1.3em
        height 44px
        transition(border-color 0.6s)

      .option.expanded
        z-index 2

      .select.expanded
          border 2px solid brand-color-2

    &.raise-label
      label
        top 5px
        font-size 10px

      .customDropdown
        .select
          padding-top 15px
          padding-bottom 5px

    &.invalid
      .customDropdown
        .select
          border 2px solid color-attention

  .input-error
    color color-attention
</style>
