import mrApi from './mrApi';

export default {
  loadAllPressAssets,
  getPagePressReleases,
};

export function loadAllPressAssets(params) {
  return mrApi.get('/api/press/loadAllPressAssets', { params });
}

export function getPagePressReleases(params) {
  return mrApi.get('/api/press/getPagePressReleases', { params });
}