const requireModule = require.context(".", false, /^(?!.*\.test\.js$).*\.js$/);
const modules = {};

requireModule.keys().forEach(fileName => {
  if (fileName === "./index.js" || fileName.includes('.test.')) {
    return;
  }

  const moduleName = fileName.replace(/(\.\/|\.js)/g, "");

  modules[moduleName] = requireModule(fileName).default;
});

export default modules;