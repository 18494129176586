<template lang="pug">
  .site-nav-mobile-v2-sub-nav.xs-12
    .header.xs-12
      button.back-button.btn-reset.xs-f-medium.lg-f-small.max-at-tweak(type="button" @click="$emit('input', null)")
        i.icon-caret-left.cta-color-1
        span.ada-tooltip back

      .nav-title.bold.upper.xs-f-medium.lg-f-small.max-at-tweak.text-color-1 {{ navItem.title }}

    .nav-content.xs-12
      .sub-nav.text-left(v-for="(subNav, index) in navItem.subNavs" :key="index")
        .nav-sub-header.upper.xs-f-small.lg-f-xsmall.max-at-tweak.text-color-2(v-if="navItem.subNavs.length > 1") {{ subNav.title }}
        ul.xs-f-medium.lg-f-small.max-at-tweak(role="menu")
          li.sub-nav-item.xs-f-medium.lg-f-small.max-at-tweak(v-for="(cta, index) in subNav.ctas" :key="index" role="none")
            a.xs-f-medium.lg-f-small.max-at-tweak(:href="cta.link.href" @click="mix_trackItemClick(cta.link.text)" role="menuitem")
              .main-title.text-color-1.xs-f-medium.lg-f-small.max-at-tweak {{ cta.link.text }}
              .sub-title.text-color-3.xs-f-xsmall.max-at-tweak(v-if="cta.subText") {{cta.subText}}

</template>

<script>
  import SiteNavTrackMixin from "@components/SiteNav/SiteNavTrackMixin/SiteNavTrackMixin";

  export default {
    name: "SiteNavMobileV2SubNav",

    mixins: [SiteNavTrackMixin],

    props: {
      value: {
        type: String
      },
      navItem: {
        type: Object
      }
    }
  };
</script>

<style scoped lang="stylus">
  .site-nav-mobile-v2-sub-nav
    padding-bottom 4em
    
    .header
      position relative
      
      .back-button
        padding 1em
        position absolute
        top 50%
        left 0
        margin-top -1.75em
        
      .nav-title
        padding 1em
        
    .nav-sub-header
      background-color ui-color-3
      padding 0.5em 3%
      margin 0.25em 0
      display inline-block
        
    .nav-content
      height 100%
      overflow-y scroll

    li.sub-nav-item
      padding 0.5em 3%
    
</style>
