const ReviewsByColor = () => import(/* webpackChunkName: "pdpEntry" */ './ReviewsByColor');

export default [{
  path: '/madison-reed-reviews/:urlKey',
  name: 'reviewsByColor',
  component: ReviewsByColor,
  meta: {
    noScroll: true
  },
  props: true,
  scrollBehavior(to) {
    // allow scroll to anchor
    if (to.hash) {
      return {
        selector: to.hash
      };
    }
  }
}];