<template lang="pug">
   .simple-carousel
      button.left-nav(v-if="showArrows" type="button" @click="prev")
        slot(name="left-nav-content")
          span.icon-caret-left.text-color-2.xs-f-large.max-at-tweak.non-focusable(tabindex="-1")
            span.ada-tooltip previous

      .carousel(:class="{ 'arrows-padding': showArrows }")
        slick(ref="slick" v-bind="carouselOptions" @init="handleInit" @afterChange="$emit('afterChange', Array.from(arguments)[0])")
          slot

      button.right-nav(v-if="showArrows" type="button" @click="next")
        slot(name="right-nav-content")
          span.icon-caret-right.text-color-2.xs-f-large.max-at-tweak.non-focusable(tabindex="-1")
            span.ada-tooltip next
        
</template>

<script>
  export default {
    name: 'SimpleCarousel',

    props: {
      options: {
        type: Object,
        default: () => ({}),
      },
    },

    data() {
      return {
        showArrows: false,
      };
    },

    computed: {
      carouselOptions() {
        return {
          slidesToShow: 1,
          infinite: true,
          autoplay: true,
          dots: false,
          arrows: false,
          lazyLoad: 'progressive',
          ...this.options,
        };
      },
    },

    created() {
      if (this.options.arrows) {
        this.showArrows = this.options.arrows;
      }
    },

    methods: {
      /*
        Navigates to the previous slide
      */
      prev() {
        this.$refs.slick.prev();
      },

      /*
        Navigates to the next slide
      */
      next() {
        this.$refs.slick.next();
      },

      /*
        Returns the current slide index
      */
      currentSlide() {
        return this.$refs.slick.slickCurrentSlide();
      },

      /*
        Navigates to a slide by index
      */
      goTo(aNumber, dontAnimate) {
        this.$refs.slick.goTo(aNumber, dontAnimate === true);
      },

      /*
        Pauses autoplay
      */
      pause() {
        this.$refs.slick.pause();
      },

      /*
        Starts autoplay
      */
      play() {
        this.$refs.slick.play();
      },

      handleInit() {
        this.$nextTick(() => {
          if (this.$refs.slick) {
            if (this.$refs.slick.breakpoint && this.$refs.slick.responsive) {
              let breakpointObj = this.$refs.slick.responsive.find(breakpoint => breakpoint.breakpoint == this.$refs.slick.breakpoint);
              if (breakpointObj && breakpointObj.settings) {
                this.showArrows = breakpointObj.settings.arrows;
                return;
              }
            }

            this.showArrows = this.$refs.slick.arrows;
          }
        });
      },
    },
  };
</script>

<style lang="stylus">
  .simple-carousel
    display block
    position relative
    width 100%
    .slick-arrow
      display none !important
    .carousel
      width 100%
      max-height 100%
      .slick-slider
        margin-bottom 0px
        .slide
          padding 0px 5px
    .left-nav
    .right-nav
      height 100%
      position absolute
      background-color transparent
      border 0px
      top 0
    .left-nav
      left -45px
    .right-nav
      right -45px
    @media mq-mobile
      .carousel
        &.arrows-padding
          padding 0px 10%
      .left-nav
        left -2%
      .right-nav
        right -2%
</style>
