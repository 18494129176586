<template lang="pug">
  .section-block.section(:class="classes")
    SectionTitle(v-if="title" :text="title")
    .dashboard-inner.clearfix(:class="{ 'limit-width': limitWidth }")
      slot

</template>

<script>
  import SectionTitle from "../SectionTitle";
  export default {
    name: "SectionBlock",
    components: {SectionTitle},
    props: {
      title: {
        type: String,
        required: false
      },

      limitWidth: {
        type: Boolean,
        required: false,
        default: false
      },

      noPadding: {
        type: Boolean,
        required: false,
        default: false
      },

      noMargin: {
        type: Boolean,
        require: false,
        default: false,
      },

      transparent: {
        type: Boolean,
        required: false,
        default: false
      }
    },

    computed: {
      classes() {
        let classes = [];
        if (this.noPadding) {
          classes.push('no-padding');
        }

        if (this.noMargin) {
          classes.push('no-margin');
        }

        if (this.transparent) {
          classes.push('transparent');
        }

        return classes;
      }
    }
  };
</script>

<style scoped lang="stylus">
  .section-block
    &.no-padding
      padding 0 !important
      overflow hidden

    &.no-margin
      margin 0 !important

    h3
      text-align center
      margin 0.7em 0em

    .dashboard-inner
      width 100%

      &.limit-width
        max-width 33em
        margin auto

</style>
