module.exports = {
  numberOfPerfectPairsForFrequency,
  numberOfPerfectPairs,
  qtyOfPairableColorKits,
  qtyOfPairableGloss,
  isPerfectPairEligible,
  hasPairableColorKitWithMatchingLevel,
  advisorLevelMatchesProductLevel,
  advisorLevel,
  hasPairableColorKitAndGloss,
  hasPairableColorKit,
  hasPairableGloss,
  isPairableColorKit,
  isPairableGloss,
  isOnPerfectPairDenyList,
  colorLevel,
  isColorKit,
  isGloss,
  isProductType
};

/**
 * Returns the number of perfect pairs in cartItems with a specific frequency
 *
 * @param {number} frequency A specific subscription frequency.
 * @param {array}  cartItems An array of cart items.
 */
function numberOfPerfectPairsForFrequency(frequency, cartItems) {
  let itemsByFrequency = cartItems.filter(item => item.subscription == frequency);
  return numberOfPerfectPairs(itemsByFrequency);
}

/**
 * Returns the quantity of perfect pairs in cartItems
 *
 * @param {object} advisor   An object with advisor answers.
 * @param {array}  cartItems An array of cart items.
 */
function numberOfPerfectPairs(cartItems) {
  return Math.min(qtyOfPairableColorKits(cartItems), qtyOfPairableGloss(cartItems));
}

/**
 * Returns the total quantity of color kits that could be used for perfect pair in cartItems
 *
 * @param {array}  cartItems An array of cart items.
 */
function qtyOfPairableColorKits(cartItems) {
  let colorKitItems = cartItems.filter(item => isPairableColorKit(item.product));
  return colorKitItems.reduce((total, item) => {
    return total + item.qty;
  }, 0);
}

/**
 * Returns the total quantity of gloss products in cartItems
 *
 * @param {array} cartItems An array of cart items
 */
function qtyOfPairableGloss(cartItems) {
  let glossItems = cartItems.filter(item => isPairableGloss(item.product));
  return glossItems.reduce((total, item) => {
    return total + item.qty;
  }, 0);
}

/**
 * Checks if products contains a perfect pair of color_kit and gloss based on advisor.
 *
 * @param {object} advisor  An object with advisor answers.
 * @param {array}  products An array of products.
 */
function isPerfectPairEligible(advisor, products) {
  if (!advisor || !products || !products.length) {
    return false;
  }

  return meetsPerfectPairAdvisorRequirements(advisor) && hasPairableColorKitWithMatchingLevel(advisor, products) && hasPairableGloss(products);
}

/**
 * checks if the advisor has the right answers to see perfect pair offer
 *
 * @param {object} advisor An object with advisor answers.
 */
function meetsPerfectPairAdvisorRequirements(advisor) {
  return advisor.treated && advisor.hair_shade;
}

/**
 * Checks if products contains a pairable color_kit that matches the treated color level from the advisor.
 *
 * @param {object} advisor  An object with advisor answers.
 * @param {array}  products An array of products.
 */
function hasPairableColorKitWithMatchingLevel(advisor, products) {
  return products.some(product => {
    return isPairableColorKit(product) && advisorLevelMatchesProductLevel(advisor, product);
  });
}

/**
 * Checks if a products color level matches the treated level from the advisor
 *
 * @param {object} advisor An object with advisor answers.
 * @param {object} product A product object.
 */
function advisorLevelMatchesProductLevel(advisor, product) {
  return advisorLevel(advisor) && colorLevel(product) && advisorLevel(advisor) == colorLevel(product);
}

/**
 * returns a color level based on advisor answers
 *
 * @param {object} advisor An object with advisor answers.
 */
function advisorLevel(advisor) {
  if (advisor.treated && advisor.hair_shade) {
    return advisor.hair_shade;
  } else if (!advisor.treated && advisor.natural_hair_shade) {
    return advisor.natural_hair_shade;
  } else {
    return null;
  }
}

/**
 * Checks if products contains a pairable color_kit and gloss
 *
 * @param {array} products An array of products.
 */
function hasPairableColorKitAndGloss(products) {
  return hasPairableColorKit(products) && hasPairableGloss(products);
}

/**
 * Checks if products contains a pairable color kit
 *
 * @param {array} products An array of products.
 */
function hasPairableColorKit(products) {
  return products.some(product => {
    return isPairableColorKit(product) && !isOnPerfectPairDenyList(product);
  });
}

/**
 * Checks if products contains a pairable gloss
 *
 * @param {array} products An array of products.
 */
function hasPairableGloss(products) {
  return products.some(product => isPairableGloss(product));
}

/**
 * Checks if product is a color_kit and can be part of a perfect pair based on advisor.
 *
 * @param {object} product A product object.
 */
function isPairableColorKit(product) {
  return isColorKit(product) && !isOnPerfectPairDenyList(product);
}

/**
 * Checks if product is gloss and can be part of a perfect pair
 *
 * @param {object} product A product object.
 */
function isPairableGloss(product) {
  return isGloss(product) && !isOnPerfectPairDenyList(product);
}

/**
 * returns true if the product is on the perfect pair denyList
 *
 * @param {object} product A product object.
 */
function isOnPerfectPairDenyList(product) {
  let perfectPairDenyListedCodes = ['CRG0C0', '11AA', '11GV', '11VA'];

  return perfectPairDenyListedCodes.some(code => code == product.code);
}

/**
 * Returns the color level of the product
 *
 * @param {object} product A product object.
 */
function colorLevel(product) {
  let number = product.color_number || product.color_shade;
  if (!number && number !== 0) {
    return null;
  }
  return number.split('.')[0];
}

/**
 * checks if product is a color_kit
 *
 * @param {object} product A product object.
 */
function isColorKit(product) {
  return isProductType(product, 'color_kit');
}

/**
 * Checks if product is gloss
 *
 * @param {object} product A product object.
 */
function isGloss(product) {
  return isProductType(product, 'gloss');
}

/**
 * Checks if product is of productType
 *
 * @param {object} product     A product object.
 * @param {string} productType A product_type code.
 */
function isProductType(product, productType) {
  return product && product.product_type == productType;
}
