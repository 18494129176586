const reviewStatus = {
  APPROVED: 'approved',
  PENDING: 'pending',
  DELETED: 'deleted'
};

const powerReviewStatus = {
  UPLOADED: 'uploaded',
  REJECTED: 'rejected', //- will not retry upload
  FAILED: 'failed', //- will try upload again,
  MISSING_DATA: 'missing_data' //- will not retry upload
};

const rating = {
  1: 1,
  2: 2,
  3: 3,
  4: 4,
  5: 5
};

const ratingOptions = [
  {
    label: '5 stars',
    value: rating[5]
  },
  {
    label: '4 stars',
    value: rating[4]
  },
  {
    label: '3 stars',
    value: rating[3]
  },
  {
    label: '2 stars',
    value: rating[2]
  },
  {
    label: '1 stars',
    value: rating[1]
  }
];

const lookAndFeel = {
  MORE_DRY: 'more_dry',
  SAME: 'same',
  SOFT_AND_SHINY: 'soft_and_shiny'
};

const lookAndFeelOptions = [
  {
    label: 'More dry',
    value: lookAndFeel.MORE_DRY
  },
  {
    label: 'The same',
    value: lookAndFeel.SAME
  },
  {
    label: 'Soft & shiny',
    value: lookAndFeel.SOFT_AND_SHINY
  }
];

const grayCoverage = {
  DIDNT_COVER_GRAYS: 'didnt_cover_grays',
  COVERED_SOME_GRAYS: 'covered_some_grays',
  COVERED_ALL_GRAYS: 'covered_all_grays'
};

const grayCoverageOptions = [
  {
    label: "Didn't cover grays",
    value: grayCoverage.DIDNT_COVER_GRAYS
  },
  {
    label: "Covered some grays",
    value: grayCoverage.COVERED_SOME_GRAYS
  },
  {
    label: "Covered all grays",
    value: grayCoverage.COVERED_ALL_GRAYS
  }
];

const hairTypes = {
  STRAIGHT: 'straight',
  WAVY: 'wavy',
  CURLY: 'curly',
  COILY: 'coily'
};

const hairTypeOptions = [
  {
    label: 'Straight',
    value: hairTypes.STRAIGHT
  },
  {
    label: 'Wavy',
    value: hairTypes.WAVY
  },
  {
    label: 'Curly',
    value: hairTypes.CURLY
  },
  {
    label: 'Coily',
    value: hairTypes.COILY
  }
];

const grayAmount = {
  NONE: 'no_gray',
  SOME: 'less_than_50',
  MOST: 'more_than_50',
  ALL: 'all' 
};

const grayAmountOptions = [
  {
    label: "No gray",
    value: grayAmount.NONE
  },
  {
    label: "Some gray",
    value: grayAmount.SOME
  },
  {
    label: "Mostly gray",
    value: grayAmount.MOST
  },
  {
    label: "All gray",
    value: grayAmount.ALL
  }
];

const ageRange = {
  'LESS_THAN_30': 'less_than_30',
  '30_TO_40': '30_to_40',
  '41_TO_50': '41_to_50',
  '51_AND_UP': '51_and_up'
};

const ageRangeOptions = [
  {
    label: 'Under 30',
    value: ageRange.LESS_THAN_30
  },
  {
    label: '30 to 40',
    value: ageRange['30_TO_40']
  },
  {
    label: '41 to 50',
    value: ageRange['41_TO_50']
  },
  {
    label: 'Over 51',
    value: ageRange['51_AND_UP']
  }
];

const sortBy = {
  NEWEST: 'newest',
  OLDEST: 'oldest',
  HIGHEST: 'highest',
  LOWEST: 'lowest',
  HELPFUL: 'helpful'
};

const sortByOptions = [
  {
    label: 'Newest',
    value: sortBy.NEWEST
  },
  {
    label: 'Oldest',
    value: sortBy.OLDEST
  },
  {
    label: 'Highest rated',
    value: sortBy.HIGHEST
  },
  {
    label: 'Lowest rated',
    value: sortBy.LOWEST
  },
  {
    label: 'Most helpful',
    value: sortBy.HELPFUL
  }
];

module.exports = {
  reviewStatus,
  powerReviewStatus,
  rating,
  ratingOptions,
  lookAndFeel,
  lookAndFeelOptions,
  grayCoverage,
  grayCoverageOptions,
  hairTypes,
  hairTypeOptions,
  grayAmount,
  grayAmountOptions,
  ageRange,
  ageRangeOptions,
  sortBy,
  sortByOptions
};

Object.freeze(module.exports);
