import {
  searchAll,
} from  "../../services/vueSearchSvc";

const state = {
  page: 1,
  pageSize: 10,
  searchVal: '',
  numResults: 0,
  searchResults: [],
  from: 0,
  resultsLoaded: false
};

export const actions = {
  searchAll({ state, commit, dispatch}) {

    let payload = {
      q: state.searchVal,
      size: state.pageSize,
      from: state.from,
    };

    return new Promise((resolve, reject) => {
      return searchAll(payload).then((res) => {
        if (res.data && res.data.total && res.data.total > 0) {
          commit('setSearchResults', res.data.hits);
          commit('setNumResults', res.data.total);
        }
        commit('setResultsLoaded', true);
        resolve();
      }).catch(() => {
        commit('setResultsLoaded', true);
        let errMsg = 'Sorry, there were no results for your search.';
        dispatch('notifyError', errMsg, { root: true });
        reject();
      });
    });
  },
};

export const mutations = {
  setSearchVal(state, val) {
    state.searchVal = val;
    state.page = 1;
    state.from = 0;
  },

  setSearchResults(state, val) {
    state.searchResults = val;
  },

  setNumResults(state, val) {
    state.numResults = val;
  },

  setNextPage(state) {
    state.page++;
    state.from = state.from + state.pageSize;
  },

  setPreviousPage(state) {
    state.page--;
    state.from = state.from - state.pageSize;
  },

  setResultsLoaded(state, val) {
    state.resultsLoaded = val;
  }
};

export default {
  namespaced: true,
  state,
  actions,
  mutations
};