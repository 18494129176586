import vueCustomerSvc from '../../services/vueCustomerSvc';
import vueReviewSvc from '../../services/vueReviewSvc';

const Welcome = () =>  import(/* webpackChunkName: "rcd-welcome" */ './Welcome');
const Orders = () =>  import(/* webpackChunkName: "rcd-group-orders" */ './Orders');
const OrderDetails = () =>  import(/* webpackChunkName: "rcd-group-orders" */ './Orders/OrderDetails');
const Review = () => import(/* webpackChunkName: "rcd-group-review" */ './Review');
const ReviewableProducts = () => import(/* webpackChunkName: "rcd-group-review" */ './Review/ReviewableProducts');
const InactiveSubscriptions = () =>  import(/* webpackChunkName: "rcd-inactivesubs" */ './Subscriptions/InactiveSubscriptions');
const EditAutoDelivery = () =>  import(/* webpackChunkName: "rcd-group-editsub" */ './Subscriptions/EditAutoDelivery');
const EditAutoDeliveryDashboard = () =>  import(/* webpackChunkName: "rcd-group-editsub" */ './Subscriptions/EditAutoDelivery/EditAutoDeliveryDashboard');
const EditAutoDeliveryCancelReasons = () =>  import(/* webpackChunkName: "rcd-group-cancelflow" */ './Subscriptions/EditAutoDelivery/EditAutoDeliveryCancelReasons/EditAutoDeliveryCancelReasons');
const CancelSubscriptionQuiz = () => import(/* webpackChunkName: "rcd-group-cancelflow" */ './Subscriptions/EditAutoDelivery/CancelSubscriptionQuiz');
const EditAutoDeliveryCancelColorIssue = () =>  import(/* webpackChunkName: "rcd-group-cancelflow" */ './Subscriptions/EditAutoDelivery/EditAutoDeliveryCancelColorIssue');
const EditAutoDeliveryCancel = () =>  import(/* webpackChunkName: "rcd-group-cancelflow" */ './Subscriptions/EditAutoDelivery/EditAutoDeliveryCancel');
const EditAutoDeliveryCancelTooMuch = () =>  import(/* webpackChunkName: "rcd-group-cancelflow" */ './Subscriptions/EditAutoDelivery/EditAutoDeliveryCancelTooMuch');
const EditAutoDeliveryCancelTooHard = () =>  import(/* webpackChunkName: "rcd-group-cancelflow" */ './Subscriptions/EditAutoDelivery/EditAutoDeliveryCancelTooHard');
const EditAutoDeliveryCancelSalon = () =>  import(/* webpackChunkName: "rcd-group-cancelflow" */ './Subscriptions/EditAutoDelivery/EditAutoDeliveryCancelSalon');
const CancelMembershipReasons = () => import(/* webpackChunkName: "rcd-membership-cancelflow" */ './Membership/CancelMembership/CancelMembershipReasons');
const CancelMembershipSubreasons = () => import(/* webpackChunkName: "rcd-membership-cancelflow" */ './Membership/CancelMembership/CancelMembershipSubreasons');
const CancelLimitlessPlusReasons = () => import(/* webpackChunkName: "rcd-membership-cancelflow" */ './Membership/CancelMembership/CancelLimitlessPlusReasons');
const CancelMembershipReasonDescription = () => import(/* webpackChunkName: "rcd-membership-cancelflow" */ './Membership/CancelMembership/CancelMembershipReasonDescription');
const CancelMembershipUnsubscribed = () => import(/* webpackChunkName: "rcd-membership-cancelflow" */ './Membership/CancelMembership/CancelMembershipUnsubscribed');
const Membership = () => import(/* webpackChunkName: "rcd-membership" */ './Membership');
const Subscriptions = () =>  import(/* webpackChunkName: "rcd-group-subscriptions" */ './Subscriptions');
const Account = () =>  import(/* webpackChunkName: "rcd-account" */ './Account');
const Shop = () =>  import(/* webpackChunkName: "rcd-shop" */ './Shop');
const HowTo = () =>  import(/* webpackChunkName: "rcd-howto" */ './HowTo');
const CustomInstructions = () =>  import(/* webpackChunkName: "rcd-instructions" */ './HowTo/CustomInstructions');
const CancelLimitlessPlusQuiz = () => import(/* webpackChunkName: "rcd-membership-cancelflow" */ './Membership/CancelMembership/CancelLimitlessPlusQuiz');

function requireHardLogin(to, from, next) {
  vueCustomerSvc.requiresLogin().then(res => {
    if (res.data.requiresHardLogin) {
      window.location = '/notloggedin?next=' + encodeURIComponent(to.path);
      return next(false);
    }
    next();
  }).catch(() => {
    window.location = '/notloggedin?next=' + encodeURIComponent(to.path);
    return next(false);
  });
}

function requireSoftLogin(to, from, next) {
  vueCustomerSvc.requiresLogin().then(res => {
    if (res.data.requiresSoftLogin) {
      window.location = '/notloggedin?next=' + encodeURIComponent(to.path);
      return next(false);
    }
    next();
  }).catch(() => {
    window.location = '/notloggedin?next=' + encodeURIComponent(to.path);
    return next(false);
  });
}

function checkProductPurchased(to, from, next) {
  vueReviewSvc.canUserReviewUrlKey({ urlKey: to.params.productUrlKey }).then(res => {
    if (res.data.canReview) {
      return next();
    }
    window.location = '/dashboard/welcome';
    return next(false);
  });
}

export default [
  { path: '/dashboard/welcome', component: Welcome, alias: '/dashboard/welcome/recommendations' },
  { path: '/dashboard/welcome', component: Welcome, alias: '/dashboard/welcome/nextShipment' },

  //- redirect old purchase routes to new orders routes
  { path: '/dashboard/purchases/orders', redirect: '/dashboard/orders' },
  { path: '/dashboard/purchases/order-details/:orderId', redirect: '/dashboard/orders/order-details/:orderId' },

  //- order routes
  { path: '/dashboard/orders', component: Orders, beforeEnter: requireHardLogin, name: 'orders' },
  { path: '/dashboard/orders/order-details/:orderId', component: OrderDetails, props: true, beforeEnter: requireHardLogin },

  //- redirect old purchases routes to use subscription routes
  { path: '/dashboard/purchases', redirect: '/dashboard/subscriptions' },
  { path: '/dashboard/purchases/inactive-auto-delivery', redirect: '/dashboard/subscriptions/inactive-subscriptions' },
  { path: '/dashboard/purchases/edit-auto-delivery/:subId', redirect: '/dashboard/subscriptions/edit-subscription/:subId' },
  { path: '/dashboard/purchases/edit-auto-delivery/:subId/cancel', redirect: { name: 'cancel' }},
  { path: '/dashboard/purchases/edit-auto-delivery/:subId/cancel-reasons', redirect: { name: 'cancelReasons'}},
  { path: '/dashboard/purchases/edit-auto-delivery/:subId/cancel-color-issue', redirect: { name: 'cancelColorIssue'}},
  { path: '/dashboard/purchases/edit-auto-delivery/:subId/cancel-too-much', redirect: { name: 'cancelTooMuch'}},

  //- subscription routes
  { path: '/dashboard/subscriptions', component: Subscriptions, beforeEnter: requireHardLogin },
  { path: '/dashboard/subscriptions/inactive-subscriptions', component: InactiveSubscriptions, beforeEnter: requireSoftLogin },
  { path: '/dashboard/subscriptions/edit-subscription/:subId', component: EditAutoDelivery,
    beforeEnter: requireHardLogin,
    meta: {
      title: 'Manage Subscription'
    },
    children: [
      {
        path: '', name: 'subDashboard',
        component: EditAutoDeliveryDashboard,
        beforeEnter: requireHardLogin
      },
      {
        path: 'cancel',
        name: 'cancel',
        component: EditAutoDeliveryCancel,
        beforeEnter: requireHardLogin,
        meta: {
          title: 'Cancel Subscription'
        }
      },
      {
        path: 'cancel-reasons',
        name: 'cancelReasons',
        component: EditAutoDeliveryCancelReasons,
        beforeEnter: requireHardLogin,
        meta: {
          title: 'Cancel Subscription - Reasons'
        }
      },
      {
        path: 'cancel-subscription-quiz',
        name: 'cancelSubscriptionQuiz',
        component: CancelSubscriptionQuiz,
        beforeEnter: requireHardLogin,
        meta: {
          title: 'Cancel Subscription Quiz'
        }
      },
      {
        path: 'cancel-color-issue',
        name: 'cancelColorIssue',
        component: EditAutoDeliveryCancelColorIssue,
        beforeEnter: requireHardLogin,
        meta: {
          title: 'Cancel Subscription - Color Issue'
        }
      },
      {
        path: 'cancel-too-much',
        name: 'cancelTooMuch',
        component: EditAutoDeliveryCancelTooMuch,
        beforeEnter: requireHardLogin,
        meta: {
          title: 'Cancel Subscription - Too Much Color'
        }
      },
      {
        path: 'cancel-too-hard',
        name: 'cancelTooHard',
        component: EditAutoDeliveryCancelTooHard,
        beforeEnter: requireHardLogin,
        meta: {
          title: 'Cancel Subscription - Too Hard to Color'
        }
      },
      {
        path: 'cancel-salon',
        name: 'cancelSalon',
        component: EditAutoDeliveryCancelSalon,
        beforeEnter: requireHardLogin,
        meta: {
          title: 'Cancel Subscription - Returning to Salon'
        }
      },
    ],
  },

  //- account routes
  { path: '/dashboard/account', component: Account, beforeEnter: requireHardLogin },
  { path: '/dashboard/account/edit-password', component: Account, beforeEnter: requireHardLogin },
  { path: '/dashboard/account/edit-payment', component: Account, beforeEnter: requireHardLogin },
  { path: '/dashboard/account/edit-address', component: Account, beforeEnter: requireHardLogin },

  { path: '/dashboard/shop', component: Shop },

  //- how to routes
  { path: '/dashboard/how-to/custom-instructions', component: CustomInstructions },
  { path: '/dashboard/how-to', component: HowTo },

  //- review routes
  { path: '/dashboard/review/products', component: ReviewableProducts},
  { path: '/dashboard/review/:productUrlKey', component: Review, props: true, beforeEnter: checkProductPurchased, name: 'writeReview' },

  //- Membership rout
  { path: '/dashboard/membership',
    component: Membership,
    beforeEnter: requireHardLogin,
    meta: { title: 'Manage Membership' },
  },
  {
    path: '/dashboard/membership/cancel-reasons',
    name: 'cancelMembershipReasons',
    component: CancelMembershipReasons,
    beforeEnter: requireHardLogin
  },
  {
    path: '/dashboard/membership/cancel-subreasons',
    name: 'cancelMembershipSubreasons',
    component: CancelMembershipSubreasons,
    beforeEnter: requireHardLogin
  },
  {
    path: '/dashboard/membership/cancel-limitless-plus-reasons',
    name: 'cancelLimitlessPlusReasons',
    component: CancelLimitlessPlusReasons,
    beforeEnter: requireHardLogin
  },
  {
    path: '/dashboard/membership/reason-description',
    name: 'cancelMembershipReasonDescription',
    component: CancelMembershipReasonDescription,
    beforeEnter: requireHardLogin,
    props: true
  },
  {
    path: '/dashboard/membership/canceled',
    name: 'cancelMembershipUnsubscribed',
    component: CancelMembershipUnsubscribed,
    beforeEnter: requireHardLogin,
    props: true
  },
  {
    path: '/dashboard/membership/cancel-limitless-plus',
    name: 'CancelLimitlessPlusQuiz',
    component: CancelLimitlessPlusQuiz,
    beforeEnter: requireHardLogin
  },

  //- default fallback
  { path: "/dashboard*", name: 'rcdWelcome', redirect: '/dashboard/welcome' }
];
