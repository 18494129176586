<!-- WARNING: THIS COMPONENT IS NOW DEPRECATED -->
<!-- TODO: find and replace all instances where this component is being used in the code base and CMS, should be replaced with site-message-banner component -->

<template lang='pug'>
  .row.site-messages.xs-f-small.max-at-tweak(v-if="messages.length > 0")
    .site-message.xs-12(v-for='msg in messages' :class='setMessageClass(msg.importance)' v-html="msg.message")

</template>

<script>
  export default {
    name: 'SiteMessages',
    props: {
      topics: String
    },

    data: function() {
      return {
        messages: []
      };
    },

    methods: {
      setMessageClass(val) {
        var msgClass = '';
        if (val == 'high') {
          msgClass = 'color-red';
        } else if (val == 'medium') {
          msgClass = 'brand-color-2';
        }

        return msgClass;
      }
    },

    mounted() {
      if (!(this.topics && this.topics.length)) {
        return;
      }

      this.$mrApi.get('/api/siteMessages/getByTopics', {
        params: {
          topics: this.topics.toLowerCase()
        }
      }).then(res => {
        this.messages = res.data;
      });
    }
  };
</script>

<style scoped lang="stylus">
  .site-messages
    background-color #fff
    text-align center
    padding 1em

    .site-message
      margin-top 0.5em

      &:first-child
        margin-top 0
</style>
