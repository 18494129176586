<template lang='pug'>
  .track-event
    slot
</template>

<script lang='js'>
  export default {
    props: {
      event: {
        default: 'Tracking Event',
        type: String
      },
      params: {
        default: () => {},
        type: Object
      }
    },

    mounted() {
      var self = this;
      self.trackEvent(self.event, self.params);
    }
  }
</script>
