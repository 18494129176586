import mrApi from './mrApi';

export default {
  getXsellItemsForProduct,
  getXsellItemsForProducts,
  getXsellItemsForProductById,
  getXsellForActiveSubsOfProductType,
  getXsellItems,
  getXsellByContext,
  getMembershipXsellProducts
};

export function getXsellItemsForProduct(params) {
  return mrApi.get('/api/xsell/getXsellItemsForProduct', { params });
}

export function getXsellItemsForProducts(params) {
  var codesArray = params.productCodes.map(code => {
    return { productCode: code };
  });

  return Promise.all(codesArray.map(getXsellItemsForProduct));
}

export function getXsellItemsForProductById(params) {
  return mrApi.get('/api/xsell/getXsellItemsForProductById', { params });
}

export function getXsellForActiveSubsOfProductType(params) {
  return mrApi.get('/api/xsell/getXsellForActiveSubsOfProductType', { params });
}

export function getXsellItems() {
  return mrApi.get('/api/xsell/getXsellItems');
}

export function getXsellByContext() {
  return mrApi.get('/api/xsell/getXsellByContext');
}

export function getMembershipXsellProducts() {
  return mrApi.get('/api/xsell/getMembershipXsellProducts');
}
