<template lang="pug">
  .multi-select-dropdown(:class="{ open: open, closed: !open }" v-click-outside="close")
    .dropdown-wrap
      button.toggle-button.btn-reset(type="button" @click="toggle" :aria-expanded="open" aria-haspopup="true" :aria-controls="optionsId" v-html="title")
      ul.options(:id="optionsId" :aria-hidden="!open")
        li(v-for="(option, index) in options" :key="index")
          mrInputCheckbox.option(:value="valueSelected(option.value)" :label="option.label" @input="update(option.value)" :disabled="option.disabled")

</template>

<script>
  import mrInputCheckbox from './mrInputCheckbox';

  export default {
    name: "MultiSelectDropdown",

    components: {
      mrInputCheckbox
    },

    props: {
      value: {
        type: Array
      },

      options: {
        type: Array,
        default: () => ([])
      },

      title: {
        type: String
      }
    },

    data() {
      return {
        open: false
      }
    },

    computed: {
      optionsId() {
        return 'options-' + this._uid;
      }
    },

    methods: {
      close() {
        this.open = false;
      },

      toggle() {
        this.open = !this.open;
      },

      valueSelected(val) {
        return this.value.indexOf(val) != -1;
      },

      update(val) {
        var newValue = [];

        this.options.forEach(option => {
          var index = this.value.indexOf(option.value);

          if ((index != -1 && option.value != val) || (index == -1 && option.value == val)) {
            newValue.push(option.value);
          }
        });

        this.$emit('input', newValue);
      }
    }
  }
</script>

<style scoped lang="stylus">
  .multi-select-dropdown
    position relative
    font-size 14px
    display inline-block
    vertical-align top
    text-align left
    margin 0.55em 0
    width 100%
    height 44px

    .dropdown-wrap
      position absolute
      left 0
      top 0
      right 0
      border 1px solid #9b9b9b
      border-radius 3px
      background-color color-white

    .toggle-button
      position relative
      height 42px
      width 100%
      padding 0.8em
      padding-right 2.6em
      text-align left
      color brand-color-2

      &:after
        content ''
        position absolute
        width 0
        height 0
        right 0.8em
        border 0.4em solid transparent !important
        top 50%
        margin-top -0.25em

    .options
      overflow hidden
      width 100%
      background-color color-white

      li
        &:first-child
          padding-top 5px

      .option
        >>>label
          display block
          height 44px
          width 100%
          padding 0.8em
          padding-right 2.6em
          text-align left

    &.closed
      .dropdown-wrap
        transition(z-index 0ms 500ms)
        z-index 0

      .toggle-button
        &:after
          border-top 0.5em solid #333 !important

      .options
        transition(visibility 0ms 500ms\, max-height 500ms ease-in-out)
        max-height 0
        z-index 0
        visibility hidden

    &.open
      .dropdown-wrap
        transition(z-index 0ms)
        z-index 1

      .toggle-button
        &:after
          border-bottom 0.5em solid #333 !important
          margin-top -0.75em

      .options
        transition(visibility 0ms\, max-height 500ms ease-in-out)
        max-height 330px
        overflow auto
        z-index 100
        visibility visible

</style>
