<template lang="pug">
  transition(name="fade-slide-left" mode="out-in")
    .site-nav-mobile-v2.xs-12
      .site-nav-mobile-header.xs-12
        .top-wrap.xs-12
          .left-wrap
            button.hamburger(type="button" @click="closeNav"  :class="{ open: siteNavMobileNavOpen }"  data-mr-ass="hamburger-menu"  :aria-label='siteNavMobileNavOpen ? "Close Menu": "Open Menu"')
              .stripe-top
              .stripe-bottom

          .middle-wrap
            a.mr-logo(href='/' target="_self")
              span.ada-tooltip home

          .right-wrap
            button.search-trigger.btn-reset.color-black.xs-f-medium.md-f-xmedium.lg-hide.max-at-tweak(@click="toggleSearch")
              span.icon-magnify
                span.ada-tooltip open search

            a.icon-bag.color-black(href='/cart' target="_self" aria-label="Cart")
              span.ada-tooltip go to cart
              .item-count
                span {{cic}}

      transition(:name="slideAnimation" mode="out-in")
        SiteNavMobileV2MainNav(v-if="!openedNav" v-model="openedNav" :shop-nav="shopNav" :nav-items="navItems" :nav-links="navLinks")
        SiteNavMobileV2ShopNav(v-else-if="openedNav == 'shop'" v-model="openedNav" :shop-nav="shopNav")
        SiteNavMobileV2SubNav(v-else-if="navItemMap[openedNav]" v-model="openedNav" :nav-item="navItemMap[openedNav]")

</template>

<script>
  import {mapState, mapMutations, mapActions} from 'vuex';
  import SiteNavTrackMixin from "@components/SiteNav/SiteNavTrackMixin/SiteNavTrackMixin";
  import SiteNavMobileV2MainNav from "./SiteNavMobileV2MainNav";
  import SiteNavMobileV2ShopNav from "./SiteNavMobileV2ShopNav";
  import SiteNavMobileV2SubNav from "./SiteNavMobileV2SubNav";

  export default {
    name: "SiteNavMobileV2",

    mixins: [
      SiteNavTrackMixin,
    ],

    components: {
      SiteNavMobileV2MainNav,
      SiteNavMobileV2ShopNav,
      SiteNavMobileV2SubNav,
    },

    data() {
      return {
        openedNav: null,
      };
    },

    props: {
      shopNav: {
        type: Object
      },
      navItems: {
        type: Array
      },
      navLinks: {
        type: Array
      }
    },

    computed: {
      ...mapState('cart', ['cic']),

      ...mapState('siteNav', [
        'searchOpen',
        'siteNavMobileNavOpen'
      ]),

      slideAnimation() {
        return this.openedNav ? 'fade-slide-left' : 'fade-slide-right';
      },

      navItemMap() {
        let map = {};
        if (this.navItems) {
          this.navItems.forEach(item => {
            map[item.title] = item;
          });
        }
        return map;
      }
    },

    methods: {
      ...mapActions('siteNav', [
        'toggleSearch',
        'toggleMobileSiteNav'
      ]),

      ...mapMutations('siteNav', [
        'setSiteNavMobileNavOpen'
      ]),

      closeNav() {
        this.openedNav = null;
        this.toggleMobileSiteNav();
      }
    },

    mounted() {
      this.mix_trackFTVNavViewed('main');

      document.body.addEventListener('keydown', e => {
        if (e.keyCode === 27) {
          this.setSiteNavMobileNavOpen(false);
        }
      });
    },
  };
</script>

<style scoped lang="stylus">
  .site-nav-mobile-v2
    height 100%
    overflow-y auto

    .site-nav-mobile-header
      border-bottom 1px solid ui-color-3

</style>