const PdpEntry = () => import(/* webpackChunkName: "pdpEntry" */ './PdpEntry');

export default [
  {
    path: '/product/:urlKey',
    name: 'pdpPage',
    component: PdpEntry,
    meta: {
      noScroll: true
    },
    props: true,
    scrollBehavior(to) {
      // allow scroll to anchor
      if (to.hash) {
        return {
          selector: to.hash
        };
      }
    }
  },
  {
    path: '/bundle/perfect-pair/:urlKey',
    name: 'pdpPage',
    component: PdpEntry,
    meta: {
      noScroll: true
    },
    props: true,
    scrollBehavior(to) {
      // allow scroll to anchor
      if (to.hash) {
        return {
          selector: to.hash
        };
      }
    }
  },
];
