<template lang="pug">
  .site-nav-mobile-v2-shop-nav.xs-12
    transition(:name="slideAnimation" mode="out-in")
      .site-nav-mobile-v2-shop-nav-inner(v-if="!openedShopSubNav")
        .header.xs-12
          button.back-button.btn-reset.xs-f-medium.lg-f-small.max-at-tweak(type="button" @click="$emit('input', null)")
            i.icon-caret-left.cta-color-1
            span.ada-tooltip back

          .nav-title.bold.upper.xs-f-medium.lg-f-small.max-at-tweak.text-color-1 {{ shopNav.title }}

        .nav-content.xs-12
          ul.site-nav-wrap.site-nav-mobile-main(itemscope itemtype="https://schema.org/SiteNavigationElement" role="menubar")
            li.site-nav-item(v-if="shopNav.subNavs" v-for="subNav in shopNav.subNavs" :key="subNav.title" role="none")
              button.site-nav-title.xs-f-medium.max-at-tweak(@click="openShopSubNav(subNav)" role="menuitem")
                .site-nav-title-content
                  span {{ subNav.title }}
                  i.icon-caret-right.ui-color-6

          .button-cta-wrap(v-if="hasButtonCta")
            a.mr-btn.btn-block.xs-f-xsmall.max-at-tweak(:href="shopNav.buttonCta.href") {{ shopNav.buttonCta.text }}

          .image-cta-wrap(v-if="hasImageCta")
            a.image-cta(:href="shopNav.imageCta.link.href")
              img(:src="shopNav.imageCta.mobileImage.url")
              span.ada-tooltip {{shopNav.imageCta.link.text || shopNav.imageCta.mobileImage.alt_text}}

      SiteNavMobileV2ShopSubNav(v-else v-model="openedShopSubNav" :sub-nav="openedShopSubNav")
      
</template>

<script>
  import SiteNavTrackMixin from "@components/SiteNav/SiteNavTrackMixin/SiteNavTrackMixin";
  import SiteNavMobileV2ShopSubNav from "./SiteNavMobileV2ShopSubNav";

  export default {
    name: "SiteNavMobileV2ShopNav",

    mixins: [SiteNavTrackMixin],

    components: {
      SiteNavMobileV2ShopSubNav
    },

    data() {
      return {
        openedShopSubNav: null,
      };
    },

    props: {
      value: {
        type: String
      },
      shopNav: {
        type: Object
      }
    },

    computed: {
      slideAnimation() {
        return this.openedShopSubNav ? 'fade-slide-left' : 'fade-slide-right';
      },

      hasButtonCta() {
        return this.shopNav.buttonCta && this.shopNav.buttonCta.text && this.shopNav.buttonCta.href;
      },

      hasImageCta() {
        return this.shopNav.imageCta && this.shopNav.imageCta.mobileImage && this.shopNav.imageCta.link && this.shopNav.imageCta.link.href;
      }
    },

    methods: {
      openShopSubNav(subNav) {
        this.mix_trackFTVNavViewed(subNav.title);
        this.openedShopSubNav = subNav;
      },
    }
  };
</script>

<style scoped lang="stylus">
  .site-nav-mobile-v2-shop-nav
    padding-bottom 4em
    
    .header
      position relative
      
      .back-button
        padding 1em
        position absolute
        top 50%
        left 0
        margin-top -1.75em
        
      .nav-title
        padding 1em
        
    .site-nav-item
    .site-nav-title
      width 100%
      display block
      text-align left
      
    .site-nav-title
      background-color transparent
      border none
      padding 0
      width 100%
      display block
      text-align left

      &:focus-visible
        outline -webkit-focus-ring-color auto 5px

      .site-nav-title-content
        display block
        padding 0.75em 5%

        &:focus
          outline none

        .icon-caret-right
          float right     
    
    .button-cta-wrap
      padding 1em 5%
      
    .image-cta-wrap
      padding 1em 5%
      
      .image-cta
        display block
        
        img
          width 100%
          
    @media mq-tablet
      .button-cta-wrap
      .image-cta-wrap
        padding 1em 15%
        
    @media mq-desktop-md
      .button-cta-wrap
      .image-cta-wrap
        padding 1em 25%

</style>
