<template lang="pug">
  .site-nav-mobile-v2-shop-sub-nav.xs-12
    .header.xs-12
      button.back-button.btn-reset.xs-f-medium.lg-f-small.max-at-tweak(type="button" @click="$emit('input', null)")
        i.icon-caret-left.cta-color-1
        span.ada-tooltip back

      .nav-title.bold.upper.xs-f-medium.lg-f-small.max-at-tweak.text-color-1 {{ subNav.title }}

    .nav-content.xs-12
      ul.site-nav-wrap(v-if="subNav.navItems" itemscope itemtype="https://schema.org/SiteNavigationElement" role="menubar")
        li.site-nav-item(v-for="(navItem, index) in subNav.navItems" :key="index" role="none")
          a.xs-f-medium.lg-f-small.max-at-tweak(v-if="navItem.image" :href="navItem.link.href" @click="mix_trackFTVNavClick(subNav.title, navItem.link.text)" role="menuitem")
            img(:src="navItem.image.url")
            .main-title.text-color-1.xs-f-xsmall.lg-f-small.max-at-tweak {{ navItem.link.text }}
            .sub-title.text-color-3.xs-f-xxsmall.max-at-tweak(v-if="navItem.subText") {{ navItem.subText }}
          
          a.no-image.xs-f-medium.lg-f-small.max-at-tweak(v-else :href="navItem.link.href" @click="mix_trackFTVNavClick(subNav.title, navItem.link.text)" role="menuitem" :class="{'no-image': !navItem.image}")
            .a-box-xs-1x1
              .a-box-content.ui-color-11-bg
                .main-title.hv-center.brand-color-1.upper.bold.xs-f-small.lg-f-small.max-at-tweak {{navItem.link.text}}

</template>

<script>
  import SiteNavTrackMixin from "@components/SiteNav/SiteNavTrackMixin/SiteNavTrackMixin";

  export default {
    name: "SiteNavMobileV2ShopSubNav",

    mixins: [SiteNavTrackMixin],

    props: {
      value: {
        type: Object
      },
      subNav: {
        type: Object
      }
    },
  };
</script>

<style scoped lang="stylus">
  .site-nav-mobile-v2-shop-sub-nav
    padding-bottom 4em
    
    .header
      position relative
      
      .back-button
        padding 1em
        padding-left 0.25em
        position absolute
        top 50%
        left 0
        margin-top -1.75em
        
      .nav-title
        padding 1em
        
    .nav-content
      .site-nav-wrap
        display flex
        flex-direction row
        flex-wrap wrap
        justify-content space-between
        padding 0.5em 4%
        
        .site-nav-item
          width 47%
          text-align left
          
          a
            display block
            padding-bottom 1.5em
          
            img
              width 100%
              border-radius 0.5em
              
            &.no-image
              text-align center
              
              .a-box-content
                border-radius 0.5em

</style>
