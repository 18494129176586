import vueProductSvc from '../../services/vueProductSvc';
import dataToolSvc from '../../services/dataToolSvc';
import constants from './constants';

const state = {
  color_kit: [],
  color_kit_loaded: false,
  limitlessPlusProduct: {},
  membershipInfo: {},
  loadingProductsForType: false
};

export const getters = {
  limitlessPlusPerks(state) {
    let memberships = state.membershipInfo.memberships || [];
    let limitlessPlusInfo = memberships.find(membership => membership.type == 'limitless-plus');

    return limitlessPlusInfo?.perks || [];
  },

  limitlessProPerks(state) {
    let memberships = state.membershipInfo.memberships || [];
    let limitlessPlusInfo = memberships.find(membership => membership.type == 'limitless-pro');

    return limitlessPlusInfo?.perks || [];
  },

  limitlessProPlusPerks(state) {
    let memberships = state.membershipInfo.memberships || [];
    let limitlessPlusInfo = memberships.find(membership => membership.type == 'limitless-pro-plus');

    return limitlessPlusInfo?.perks || [];
  }
};

export const actions = {
  loadProductsForType({ commit }, productType) {
    commit('setLoadingProductsForType', true);
    vueProductSvc.getProductsByType({ productType }).then(({data}) => {
      commit('setProduct', { productType, data});
      commit('setProductLoaded', productType);
      commit('setLoadingProductsForType', false);
    }).catch(() => {
      commit('setLoadingProductsForType', false);
    });
  },

  loadLimitlessPlusProduct({commit}) {
    let code = constants.state.limitlessPlusProductCode;
    vueProductSvc.getProductByCode({code}).then((res) => {
      if (res.data.id) {
        commit('setLimitlessPlusProduct', res.data);
      }
    });
  },

  loadMembershipInfo({commit}) {
    dataToolSvc.getData({ mixinKey: 'membership_info'}).then((res) => {
      commit('setMembershipInfo', res.data);
    });
  }
};

export const mutations = {
  setProduct(state, { productType, data}) {
    state[productType] = data;
  },

  setLoadingProductsForType(state, val) {
    state.loadingProductsForType = Boolean(val);
  },

  setProductLoaded(state, productType) {
    state[productType + '_loaded'] = true;
  },

  setLimitlessPlusProduct(state, val) {
    state.limitlessPlusProduct = val;
  },

  setMembershipInfo(state, val) {
    state.membershipInfo = val;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};